export default class Answer {
    public id: number
    public questionId: number
    public description: string|null
    public index: number|null

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.questionId = parseInt(data.questionId)
        this.description = data.description || null
        this.index = data.index || null
    }
}