import {intOrNull} from "@/api/lib";

export default class UserQuestionnaireCategory {
    public pk: number|null
    public userQuestionnairePk: number|null
    public categoryId: number
    public progress: number

    constructor(data: any) {
        this.pk = intOrNull(data.pk)
        this.userQuestionnairePk = intOrNull(data.userQuestionnairePk)
        this.categoryId = parseInt(data.categoryId)
        this.progress = parseInt(data.progress)
    }
}