import '@babel/polyfill'
import 'mutationobserver-shim'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import i18n from './i18n'

import 'bootstrap/dist/css/bootstrap.css'


import APIClient from "@/api";

declare global {
    interface Window {
        cordova: {
            platformId: string
        }
        isCordova: () => boolean;
    }
}

window.isCordova = () => window.hasOwnProperty('cordova') && window.cordova.platformId !== 'browser'


/*
const API_BASE_URL = process.env.NODE_ENV === 'production'
    ? process.env.VUE_APP_API_BASE_URL || '/api'
    : ''
   */
const API_BASE_URL = 'https://www.intelicon.eu/raWeb/rest/raws';

createApp(App)
    .use(i18n)
    .use(store)
    .use(router)
    .use(APIClient, {
        baseUrl: API_BASE_URL
    })
    .mount('#app')
