<template>
  <div class="app-alert-wrapper" v-if="!useNative">
    <Transition name="alert">
      <div class="app-alert" :class="classNames" v-show="show">
        <div class="backdrop"></div>
        <div class="dialog" ref="dialog">
          <div class="content">
            <slot>
              <div class="message" v-html="message"></div>
            </slot>
          </div>
          <div class="button-wrapper">
            <a href="javascript:void(0)" @click="onCancel" v-if="showCancel">
              {{ cancelButtonLabel }}
            </a>
            <a href="javascript:void(0)" @click="onOk">
              {{ buttonLabel }}
            </a>
          </div>
        </div>
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue";
import emitter from "@/lib/eventBus";

const props = withDefaults(defineProps<{
  show?: boolean
  title?: string|null
  message?: string|null
  buttonLabel?: string
  showCancel?: boolean
  cancelButtonLabel?: string
  smallFont?: boolean
}>(), {
  show: false,
  title: null,
  message: null,
  buttonLabel: 'OK',
  showCancel: false,
  cancelButtonLabel: 'ABBRECHEN',
  smallFont: false,
})

const emit = defineEmits<{
  ok: []
  cancel: []
}>()

const dialog = ref<HTMLDivElement|null>(null)

const useNative = false // window.isCordova()

const classNames = computed(() => {
  const res: string[] = []
  if (props.smallFont) {
    res.push('small-font')
  }
  return res
})

/*
watch(show, () => {
  if (show.value) {
    if (useNative) {
      navigator.notification.alert(message.value, () => store.dispatch(HIDE_ALERT), title, buttonLabel.value)
    }
  }
})
*/

const show = computed(() => props.show)

watch(show, () => {
  if (show.value) {
    const nodes = dialog.value?.querySelectorAll('[autofocus]')
    if (nodes && nodes[0]) {
      // @ts-ignore
      setTimeout(() => nodes[0].focus(), 100)
    }
  }
})

const onOk = () => {
  emit('ok')
  emitter.emit('APP_ALERT_OK')
}
const onCancel = () => {
  emit('cancel')
  emitter.emit('APP_ALERT_CANCEL')
}
</script>

<style lang="scss">
.app-alert {
  --transitionTime: .2s;

  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  height: 100vh;
  z-index: 999;

  &.small-font .message {
    font-size: 8pt;

    h1 {
      font-size: 13pt;
    }
  }

  .backdrop {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background: #000;
    opacity: .6;
  }

  .dialog {
    position: absolute;
    width: calc(100vw - 50px);
    max-width: 600px;
    background: #fff;
    max-height: calc(100vh - 40px);
    overflow-y: auto;
    left: 50%;
    top: 50%;
    padding: 16px 28px;
    font-size: 12pt;
    border-radius: 3px;
    box-shadow: 0 0 18px 0 #4c4c4c;
    transform: translate(-50%, -50%);
    transition: all var(--transitionTime);

    .content {
      max-height: calc(100vh - 140px);
      overflow-y: auto;
    }
  }

  .button-wrapper {
    text-align: right;
    margin-top: 30px;

    a {
      color: var(--form-base-color);
      text-decoration: none;
      font-size: 10pt;
      display: inline-block;

      &:not(:first-child) {
        margin-left: 30px;
      }
    }
  }
}

.alert-enter-active,
.alert-leave-active {
  transition: opacity var(--transitionTime) ease;

  .dialog {
    transform: translate(-50%, -50%);
  }
}

.alert-enter-from,
.alert-leave-to {
  opacity: 0;

  .dialog {
    transform: translate(-50%, 0);
  }
}
</style>
