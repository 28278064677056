import {intOrNull} from "@/api/lib";

export default class Location {
    public id: number
    public name: string
    public registrationNr: number|null

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.name = data.name
        this.registrationNr = intOrNull(data.registrationNr)
    }
}