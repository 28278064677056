export default class QuestionnaireTopic {
    public id: number
    public name: string|null
    public description: string|null
    public questionnaireId: number

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.name = data.name || null
        this.description = data.description || null
        this.questionnaireId = parseInt(data.questionnaireId)
    }
}