<template>
  <input type="number" v-model="value">
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";

const props = defineProps<{
  modelValue: RawAnswer
  question: QuestionEntry
}>()

const emit = defineEmits(["update:modelValue"])

const value = ref<number|null>(null)

watch(value, () => emit("update:modelValue", value.value), { immediate: true })

watch(() => props.question, () => {
  value.value = (props.question.userQuestion ? props.question.userQuestion.numberValue : null) || null
}, { immediate: true, deep: true })
</script>

<style lang="scss">
.question-component.type-2 {
  input[type=number] {
    width: 100%;
    background: #bcd3a6;
    padding: 4px;
    text-align: center;

    &, &:focus-visible {
      border: none;
      outline: none;
    }
  }
}
</style>