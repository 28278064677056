import AbstractEntity from "./AbstractEntity";
import Membership from "@/api/types/Membership";

const TABLE_NAME = 'Membership'

export default class MembershipEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id', autoIncrement: true }, [
            'id',
            'code',
            'name',
            'numberMandatory',
        ])
    }

    public static async persist(db: IDBDatabase, membership: Membership) {
        const data = super.getPersistData<Membership>(membership, [
            'id',
            'code',
            'name',
            'numberMandatory',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }

    static async getAll(db: IDBDatabase): Promise<Membership[]> {
        return this._getAll<Membership>(
            db,
            TABLE_NAME,
        )
    }
}