import ResponseInterface from "./ResponseInterface";
import Membership from "@/api/types/Membership";

export default class GetMembershipsResponse implements ResponseInterface {
    public memberships: Membership[] = []

    isXmlResponse(): boolean {
        return true
    }

    parse(data: any): void {
        for (const membership of data.collection.membership) {
            this.memberships.push(new Membership(membership))
        }
    }

    setBlob(blob: Blob): void {}
}