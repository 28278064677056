<template>
  <app-alert
      :show="show"
      title="Passwort ändern"
      show-cancel
      @ok="onOk"
      @cancel="onCancel"
  >
    <form class="form" @submit.prevent="onOk">
      <label>
        Aktuelles Passwort
        <input type="password" v-model="currentPassword" autofocus required>
      </label>
      <label>
        Neues Passwort
        <input type="password" v-model="newPassword" required>
      </label>
      <label>
        Wiederholung des neuen Passworts
        <input type="password" v-model="repeat" required>
      </label>
    </form>
  </app-alert>
</template>

<script lang="ts" setup>
import AppAlert from "@/components/AppAlert.vue";
import {computed, ref} from "vue";
import {useStore} from "vuex";
import {showAlert} from "@/store";

const props = withDefaults(defineProps<{
  show?: boolean
}>(), {
  show: false
})

const emit = defineEmits<{
  ok: [ { oldPassword: string, newPassword: string } ]
  cancel: []
}>()

const store = useStore()

const currentPassword = ref<string>('')
const newPassword = ref<string>('')
const repeat = ref<string>('')

const onOk = async () => {
  if (newPassword.value !== repeat.value) {
    await showAlert('Das neue Passwort und die Bestätigung stimmen nicht überein!')
    return
  }

  if (!currentPassword.value) {
    await showAlert('Bitte geben Sie Ihr aktuelles Passwort ein!')
    return
  }

  if (!newPassword.value) {
    await showAlert('Bitte geben Sie ein neues Passwort ein!')
    return
  }

  if (!repeat.value) {
    await showAlert('Bitte bestätigen Sie das neue Passwort!')
    return
  }

  emit('ok', { oldPassword: currentPassword.value, newPassword: newPassword.value })
  currentPassword.value = ''
  newPassword.value = ''
  repeat.value = ''
}
const onCancel = () => {
  emit('cancel')
}
</script>
