import AbstractEntity from "@/db/AbstractEntity";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import UserQuestion from "@/api/types/UserQuestion";
import UserAnswer from "@/api/types/UserAnswer";
import {boolToNum} from "@/api/lib";

const TABLE_NAME = 'UserAnswer'

export default class UserAnswerEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'pk', autoIncrement: true }, [
            'pk',
            'id',
            'answerId',
            'userQuestionPk',
            'questionId',
            'dirty',
        ])
    }

    public static async persist(db: IDBDatabase, answer: UserAnswer): Promise<UserAnswer> {
        const data = this.getPersistData<UserAnswer>(answer, [
            'id',
            'userQuestionPk',
            'answerId',
            'questionId',
            'dirty',
        ])

        data.dirty = boolToNum(data.dirty)

        if (answer.pk && await this.findByPk(db, answer.pk)) {
            data.pk = answer.pk
            await this.updateByPk(db, data.pk, data)
        }
        else if (data.id && await this.findById(db, data.id)) {
            // @ts-ignore
            const newItem: UserAnswer|null = await this.updateById(db, data.id, data)
            if (newItem) {
                data.pk = newItem.pk
            }
        }
        else {
            data.pk = await super._put(db, TABLE_NAME, data)
        }

        return data
    }

    static async findForUserQuestion(db: IDBDatabase, userQuestionPk: number) {
        return super._find<UserAnswer>(
            db,
            TABLE_NAME,
            userQuestionPk,
            'userQuestionPk'
        )
    }

    static async clearForUserQuestion(db: IDBDatabase, userQuestionPk: number) {
        return super._delete(
            db,
            TABLE_NAME,
            userQuestionPk,
            'userQuestionPk'
        )
    }

    static async findByPk(db: IDBDatabase, pk: number): Promise<UserQuestionnaire|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            pk,
            'pk'
        )
    }

    static async findById(db: IDBDatabase, id: number): Promise<UserQuestionnaire|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            id,
            'id'
        )
    }

    static async updateByPk(db: IDBDatabase, pk: number, data: object) {
        return super._update(
            db,
            TABLE_NAME,
            pk,
            'pk',
            data)
    }

    static async updateById(db: IDBDatabase, id: number, data: object) {
        return super._update(
            db,
            TABLE_NAME,
            id,
            'id',
            data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}