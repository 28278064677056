<template>
  <div :class="`question-component type-${question.type}`">
    <div class="topic-category">
      <span class="topic">{{ question.topic.name }}</span><span class="category">/{{ question.category.name }}</span>
    </div>
    <div class="question-number">
      Frage Nr. {{question.numberString}}
    </div>
    <div class="navigation">
      <slot name="navigation"></slot>
    </div>
    <div class="question" v-html="question.question"></div>
    <ul class="addons" v-if="question.addOns" v-for="addOn of question.addOns" :key="addOn.id">
      <li v-html="addOn.value"></li>
    </ul>
    <div class="controls">
      <component :is="componentMapping[question.type]" :question="question" v-model="value" :class="{ disabled: !question.displayed }"></component>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue"
import TextQuestion from "@/components/question/impl/TextQuestion.vue";
import NumericQuestion from "@/components/question/impl/NumericQuestion.vue";
import ChoiceQuestion from "@/components/question/impl/ChoiceQuestion.vue";
import MultipleChoiceQuestion from "@/components/question/impl/MultipleChoiceQuestion.vue";
import DateQuestion from "@/components/question/impl/DateQuestion.vue";
import {QuestionEntry, QuestionType, RawAnswer} from "@/lib/QuestionHandler";

const componentMapping = {
  [QuestionType.TYPE_TEXT]: TextQuestion,
  [QuestionType.TYPE_NUMERIC]: NumericQuestion,
  [QuestionType.TYPE_CHOICE]: ChoiceQuestion,
  [QuestionType.TYPE_MULTIPLE_CHOICE]: MultipleChoiceQuestion,
  [QuestionType.TYPE_DATE]: DateQuestion,
}

const emit = defineEmits(['update:modelValue'])

const props = defineProps<{
  question: QuestionEntry
  modelValue: RawAnswer
}>()

const value = ref<RawAnswer>(props.modelValue)

watch(value, () => {
  emit('update:modelValue', value.value)
}, { immediate: true, deep: true })
</script>

<style lang="scss">
.question-component {
  padding-bottom: 30px;

  &, * {
    font-size: var(--question-font-size) !important;
  }

  &.disabled {
    opacity: .5;
    pointer-events: none;
  }

  .topic-category {
    margin-bottom: 0;

    .category {
      font-weight: bold;
    }
  }

  .question-number {
    text-align: center;
    margin-bottom: 10px;
    font-size: 13pt !important;
    color: #919191;
  }

  .question {
    background: var(--question-question-background);
    padding: 4px 4px 30px;
  }

  ul.addons {
    list-style-type: none;
    margin: 0;
    padding: 0;
  }

  .controls {
    width: calc(100% - 40px);
    margin: 20px auto;
  }
}
</style>
