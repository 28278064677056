import AbstractEntity from "./AbstractEntity";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import QuestionnaireCategory from "@/api/types/QuestionnaireCategory";

const TABLE_NAME = 'QuestionnaireTopic'

export default class QuestionnaireTopicEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionnaireId',
        ])
    }

    public static async persist(db: IDBDatabase, topic: QuestionnaireTopic) {
        const data = super.getPersistData<QuestionnaireTopic>(topic, [
            'id',
            'name',
            'description',
            'questionnaireId',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async findAllByQuestionnaire(db: IDBDatabase, questionnaireId: number): Promise<QuestionnaireTopic[]> {
        return this._find<QuestionnaireTopic>(
            db,
            TABLE_NAME,
            questionnaireId,
            'questionnaireId',
        )
    }

    static async getById(db: IDBDatabase, id: number) {
        return this._findOne<QuestionnaireTopic>(
            db,
            TABLE_NAME,
            id,
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}