import DependencyQuestionAnswer from "./DependencyQuestionAnswer";

export default class QuestionDependency {
    public id: number
    public questionId: number
    public dependencyQuestionId: number
    public answers: DependencyQuestionAnswer[]

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.questionId = parseInt(data.questionId)
        this.dependencyQuestionId = parseInt(data.dependencyQuestionId)

        const answers = Array.isArray(data.answers.dependencyQuestionAnswer)
            ? data.answers.dependencyQuestionAnswer
            : [data.answers.dependencyQuestionAnswer]

        this.answers = answers
            .filter((a: any) => !!a)
            .map((a: any) => new DependencyQuestionAnswer(a))
    }
}