import UserAgreement from "./UserAgreement";
import AbstractType from "@/api/types/AbstractType";
import {extractArrayValues, intOrNull} from "@/api/lib";
import Location from "@/api/types/Location";

export default class User extends AbstractType {
    public id: number|null = null
    public username: string|null = null
    public firstname: string|null = null
    public lastname: string|null = null
    public address: string|null = null
    public zip: number|null = null
    public city: string|null = null
    public lfbisNumber: string|null = null
    public farmType: number|null = null
    public email: string|null = null
    public membershipId: number|null = null
    public membershipNumber: number|null = null
    public userAgreement: UserAgreement|null = null
    public locations: Location[]

    constructor(data: any) {
        super()
        this.id = intOrNull(data.id)
        this.username = data.username
        this.firstname = data.firstname
        this.lastname = data.lastname
        this.address = data.address
        this.zip = data.zip
        this.city = data.city
        this.lfbisNumber = data.lfbisNumber
        this.farmType = data.farmType
        this.email = data.email
        this.membershipId = intOrNull(data.membershipId)
        this.membershipNumber = intOrNull(data.membershipNumber)
        this.userAgreement = new UserAgreement(data.userAgreement)

        this.locations = extractArrayValues(
            data,
            'locations.location',
            (l: any) => new Location(l)
        )
    }
}