import AbstractEntity from "@/db/AbstractEntity";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import UserQuestionEntity from "@/db/UserQuestionEntity";
import UserQuestionnaireTopicEntity from "@/db/UserQuestionnaireTopicEntity";
import UserQuestionnaireCategoryEntity from "@/db/UserQuestionnaireCategoryEntity";
import {boolToNum} from "@/api/lib";

const TABLE_NAME = 'UserQuestionnaire'

export default class UserQuestionnaireEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'pk', autoIncrement: true }, [
            'pk',
            'id',
            'releaseId',
            'state',
            'locationId',
            'deleted',
            'dirty',
        ])
    }

    public static async persist(db: IDBDatabase, questionnaire: UserQuestionnaire): Promise<UserQuestionnaire> {
        const data = this.getPersistData<UserQuestionnaire>(questionnaire, [
            'id',
            'lastQuestionId',
            'progress',
            'releaseId',
            'state',
            'locationId',
            'completionDate',
            'description',
            'deleted',
            'dirty',
        ])

        data.deleted = boolToNum(data.deleted)
        data.dirty = boolToNum(data.dirty)

        if (questionnaire.pk && await this.findByPk(db, questionnaire.pk)) {
            data.pk = questionnaire.pk
            await this.updateByPk(db, data.pk, data)
        }
        else if (data.id && await this.findById(db, data.id)) {
            // @ts-ignore
            const newItem: UserQuestionnaire|null = await this.updateById(db, data.id, data)
            if (newItem) {
                data.pk = newItem.pk
            }
        }
        else {
            data.pk = await super._put(db, TABLE_NAME, data)
        }

        if (questionnaire.questions && Array.isArray(questionnaire.questions)) {
            for (const question of questionnaire.questions) {
                question.userQuestionnairePk = data.pk
                await UserQuestionEntity.persist(db, question)
            }
        }

        if (questionnaire.topics) {
            for (const topic of questionnaire.topics) {
                topic.userQuestionnairePk = data.pk
                await UserQuestionnaireTopicEntity.persist(db, topic)
            }
        }

        if (questionnaire.categories) {
            for (const category of questionnaire.categories) {
                category.userQuestionnairePk = data.pk
                await UserQuestionnaireCategoryEntity.persist(db, category)
            }
        }

        return data
    }

    static async hasOfflineData(db: IDBDatabase) {
        const items = await super._find(
            db,
            TABLE_NAME,
            1,
            'dirty',
            (item: UserQuestionnaire) => !!(item.id || !item.deleted)
        )

        return !! items.length
    }

    static getOfflineQuestionnaires(db: IDBDatabase) {
        return super._find<UserQuestionnaire>(
            db,
            TABLE_NAME,
            1,
            'dirty',
            (item: UserQuestionnaire) => !!(item.id || !item.deleted)
        )
    }

    static async getForCheckByRelease(db: IDBDatabase, releaseId: number, locationId: number): Promise<UserQuestionnaire[]|null> {
        return super._find(
            db,
            TABLE_NAME,
            releaseId,
            'releaseId',
            (q: UserQuestionnaire) => q.locationId === locationId && q.state !== 3 && !q.deleted)
    }

    static async getForReportByRelease(db: IDBDatabase, releaseId: number, locationId: number): Promise<UserQuestionnaire[]|null> {
        return super._find(
            db,
            TABLE_NAME,
            releaseId,
            'releaseId',
            (q: UserQuestionnaire) => q.locationId === locationId && [1, 3].includes(q.state) && !q.deleted)
    }

    static async getForLocation(db: IDBDatabase, locationId: number): Promise<UserQuestionnaire[]> {
        return super._find<UserQuestionnaire>(
            db,
            TABLE_NAME,
            locationId,
            'locationId',
        )
    }

    static async findByPk(db: IDBDatabase, pk: number): Promise<UserQuestionnaire|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            pk,
            'pk'
        )
    }

    static async findById(db: IDBDatabase, id: number): Promise<UserQuestionnaire|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            id,
            'id'
        )
    }

    static async updateByPk(db: IDBDatabase, pk: number, data: object) {
        return super._update(
            db,
            TABLE_NAME,
            pk,
            'pk',
            data)
    }

    static async updateById(db: IDBDatabase, id: number, data: object) {
        return super._update(
            db,
            TABLE_NAME,
            id,
            'id',
            data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}
