import AbstractEntity from "./AbstractEntity";
import UserQuestionnaireCategory from "@/api/types/UserQuestionnaireCategory";

const TABLE_NAME = 'UserQuestionnaireCategory'

export default class UserQuestionnaireCategoryEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'pk', autoIncrement: true }, [
            'pk',
            'userQuestionnairePk',
            'categoryId',
        ])
    }

    public static async persist(db: IDBDatabase, category: UserQuestionnaireCategory) {
        const data = super.getPersistData<UserQuestionnaireCategory>(category, [
            'userQuestionnairePk',
            'categoryId',
            'progress',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }

    static async findByCategoryId(db: IDBDatabase, userQuestionnairePk: number, categoryId: number) {
        const res = await this._find(
            db,
            TABLE_NAME,
            categoryId,
            'categoryId',
            (item: UserQuestionnaireCategory) => item.userQuestionnairePk === userQuestionnairePk,
        )

        return res ? res[0] : null
    }
}