<template>
  <app-activity title="Risiko - Analyse" :icons="icons" @icon-click="onIconClick" id="questionnaire-overview-activity">
    <div class="location-selection-wrap">
      <location-selection/>
    </div>

    <header>Fragebogen auswählen</header>

    <div v-if="mode === 'check'" class="notice" @click="showNoticeAlert">
      Hinweise zum Ausfüllen &hellip;
    </div>

    <questionnaire-list
        :mode="mode"
        :release-id="releaseId"
        :questionnaire-id="questionnaireId"
        :user-questionnaire-id="userQuestionnairePk"
        v-model:release-id="releaseId"
        v-model:questionnaire-id="questionnaireId"
        v-model:user-questionnaire-id="userQuestionnairePk"/>
  </app-activity>
</template>

<script lang="ts" setup>
import AppActivity from "@/components/AppActivity.vue";
import {useRoute, useRouter} from "vue-router";
import {useApiClient} from "@/api";
import {useStore} from "vuex";
import {hideSpinner, showAlert, showAlertSmall, showSpinner} from "@/store";
import {ICON_CANCEL, ICON_FORMULAR_ICON, ICON_OK, ICON_REFRESH} from "@/lib/icons";
import {ref} from "vue";
import QuestionnaireList from "@/components/QuestionnaireList.vue";
import emitter from "@/lib/eventBus";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import getDb from "@/db/Database";
import GetReportResponse from "@/api/responses/GetReportResponse";
import QuestionnaireReleaseEntity from "@/db/QuestionnaireReleaseEntity";
import ReportRequest from "@/api/requests/ReportRequest";
import LocationSelection from "@/components/LocationSelection.vue";

const store = useStore()
const router = useRouter()
const route = useRoute()
const api = useApiClient()

const mode: string = <string>route.params.mode

const configuration = store.state.configuration

const showNoticeAlert = () => {
  showAlertSmall(`<html>
    <body>
        <h1>
            Risikoanalyse:
        </h1>
        <p>
            Die Risikoanalyse ist 1x im Jahr für jede Produktionsart durchzuführen.
        </p>
        <p>
            Das Kapitel Stallklima ist 2x pro Jahr auszufüllen (im Sommer und Winter).
        </p>
        <p>
            Um einen besseren Überblick über die Situation am Betrieb zu bekommen, müssen bei der Beantwortung der Fragen die einzelnen Produktionsstufen (außer Saugferkel) am Anfang und am Ende beurteilt werden.
        </p>
        <p>
            In der Anwendung können zu Beginn die Bereiche Allgemein, Anfang, Ende, Sommer und Winter gewählt werden, um nur die Fragen aus der gewünschten Kategorie zu erhalten.
        </p>
        <p>
            Achtung bei Saugferkeln gibt es die Kategorien Anfang nicht!
        </p>
        <p>
            Die offen gebliebenen Fragen können zu einem späteren Zeitpunkt ausgefüllt werden.
        </p>
        <p>
            Der beantwortete Fragebogen kann abschließend ausgedruckt werden und bei behördlichen Kontrollen vorgelegt werden.
        </p>
    </body>
</html>`)
}

const icons = [
    ICON_FORMULAR_ICON,
    ICON_OK,
    ICON_REFRESH,
    ICON_CANCEL
]

const releaseId = ref<number|null>(null)
const questionnaireId = ref<number|null>(null)
const userQuestionnairePk = ref<number|null>(null)

const refresh = async () => {
  if (await UserQuestionnaireEntity.hasOfflineData(getDb())) {
    store.commit('offline/PROMPT_UPLOAD')
  } else {
    showSpinner()
    api.getReleases(store.state.configuration?.sessionId)
      .then(() => {
        store.commit('SET_LAST_REFRESH', new Date())
        hideSpinner()
        emitter.emit('RELEASES_LOADED')
      })
      .catch((err: any) => {
        hideSpinner()
        showAlert(err.message)
      })
  }
}

const onIconClick = (icon: string) => {
  switch (icon) {
    case ICON_FORMULAR_ICON:
      if (!userQuestionnairePk.value) {
        showAlert('Bitte wählen Sie einen Fragebogen aus!')
        break
      }
      (async () => {
        if (userQuestionnairePk.value) {
          const userQuestionnaire = await UserQuestionnaireEntity.findByPk(getDb(), userQuestionnairePk.value)
          if (userQuestionnaire && userQuestionnaire.id && !userQuestionnaire.dirty) {
            showSpinner()
            const request = new ReportRequest(6, userQuestionnaire.description, userQuestionnaire.id)
            api.createReport(store.state.configuration.sessionId, request)
                .then(async (res: GetReportResponse) => {
                  try {
                    await res.openFile()
                  } catch (error: any) {
                    showAlert('Fehler: ' + error.message)
                  }
                  hideSpinner()
                })
                .catch(err => {
                  console.error('ERROR', err)
                  hideSpinner()
                })
          }
        }
      })()

      break
    case ICON_OK:
      if (!userQuestionnairePk.value) {
        showAlert('Bitte wählen Sie einen Fragebogen aus')
        break
      }
      (async () => {
        if (userQuestionnairePk.value && releaseId.value) {
          const questionnaire = await UserQuestionnaireEntity.findByPk(getDb(), userQuestionnairePk.value)
          const release = await QuestionnaireReleaseEntity.find(getDb(), releaseId.value)
          store.commit('SET_QUESTIONNAIRE', { questionnaire, release })
          switch (mode) {
            case 'check':
              await router.push({ name: 'categoryFilter' });
              break
            case 'analyze':
              await router.push({ name: 'topicOverview' });
              break
            case 'optimize':
              await router.push({ name: 'optimization' });
              break
            default:
              showAlert('not implemented')
          }

        }
      })()
      break
    case ICON_REFRESH:
      refresh()
      break
    case ICON_CANCEL:
      router.push({ name: 'menu' })
      break
  }
}

let needsRefresh = false
if (!store.state.configuration?.lastRefresh) {
  needsRefresh = true
} else {
  const lastRefresh: Date = store.state.configuration.lastRefresh
  const hoursAgo = ((new Date()).getTime() - lastRefresh.getTime()) / 1000 / 60 / 60
  if (hoursAgo >= 24) {
    needsRefresh = true
  }
}

if (needsRefresh) {
  refresh()
}
</script>

<style lang="scss">
#questionnaire-overview-activity {
  header {
    background: var(--questionnaire-header-color);
    font-size: var(--content-header-font-size);
    font-weight: 500;
    text-align: center;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-top: -5px;
    margin-bottom: 15px;
  }

  .notice {
    font-size: 11pt;
    color: var(--form-base-color);
    cursor: pointer;
  }

  .location-selection-wrap {
    margin-left: -15px;
    width: calc(100% + 30px);
  }
}
</style>