<template>
  <div class="questionnaire-list">
    <div class="row-level2" v-for="answer of question.answers" :key="answer.id">
      <div class="radio">
        <input type="radio" :id="`answer-${answer.id}`" :name="`choice-${question.id}`" :value="answer.id" v-model="value">
      </div>
      <label :for="`answer-${answer.id}`">{{ answer.description }}</label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";

const props = defineProps<{
  modelValue: RawAnswer
  question: QuestionEntry
}>()

const emit = defineEmits(["update:modelValue"])

const value = ref<number|null>(null)

watch(value, () => emit("update:modelValue", value.value), { immediate: true })

watch(() => props.question, () => {
  value.value = props.question.selectedAnswers ? props.question.selectedAnswers[0]?.id : null
}, { immediate: true, deep: true })
</script>