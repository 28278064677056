import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import UserQuestion from "@/api/types/UserQuestion";
import UserQuestionEntity from "@/db/UserQuestionEntity";
import UserAnswerEntity from "@/db/UserAnswerEntity";
import getDb from "@/db/Database";

export default class UserQuestionnaireRequest {
    private userQuestionnaire: UserQuestionnaire;

    constructor(userQuestionnaire: UserQuestionnaire) {
        this.userQuestionnaire = userQuestionnaire
    }

    async get() {
        // @ts-ignore
        this.userQuestionnaire.questions = {
          // @ts-ignore
            question: await Promise.all((await UserQuestionEntity.findByQuestionnairePk(getDb(), <number>this.userQuestionnaire.pk))
                .map(async (question) => {
                  // @ts-ignore
                  const answers = {
                    userAnswer: (await UserAnswerEntity.findForUserQuestion(getDb(), <number>question.pk))
                      .map(userAnswer => ({ 
                          answerId: userAnswer.answerId,
                          questionId: userAnswer.questionId,
                        }))
                  }
                  
                  return {
                      id: question.id,
                      questionId: question.questionId,
                      stringValue: question.stringValue,
                      numberValue: question.numberValue,
                      dateValue: question.dateValue,
                      answers,
                  }
                }))
        }
        return this
    }
}