<template>
  <app-alert
      :show="show"
      title="Offline-Daten vorhanden"
      button-label="DATEN ÜBERTRAGEN"
      show-cancel
      @ok="onOk"
      @cancel="onCancel"
  >
    Es sind noch Daten lokal gespeichert. Bitte übertragen Sie diese vorher auf den Server!
  </app-alert>
</template>
<script setup lang="ts">
import AppAlert from "@/components/AppAlert.vue";
import {useStore} from "vuex";
import {computed} from "vue";
import {hideSpinner, showAlert, showSpinner} from "@/store";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import getDb from "@/db/Database";
import {useApiClient} from "@/api";
import ConfigurationEntity from "@/db/ConfigurationEntity";

const store = useStore()
const api = useApiClient()

const show = computed(() => store.state.offline.promptUpload)

const onCancel = () => store.commit('offline/PROMPT_UPLOAD', false)

const onOk = async () => {
  onCancel()
  showSpinner()
  const configuration = await ConfigurationEntity.get(getDb())
  if (configuration) {
    const userQuestionnaires = await UserQuestionnaireEntity.getOfflineQuestionnaires(getDb())
    for (const questionnaire of userQuestionnaires) {
      try {
        await api.saveUserQuestionnaire(configuration.sessionId, questionnaire)
        showAlert('Der Fragebogen erfolgreich am Server gespeichert!')
      } catch (err: any) {
        console.error(err)
        showAlert(err.message)
      }
    }
  }
  hideSpinner()
}
</script>
