export const extractArrayValues = <T>(data: any, path: string, objectCallback: (item: any) => T): T[] => {
    const chunks = path.split('.')

    let current = data
    for (const chunk of chunks) {
        if (typeof current[chunk] === 'undefined') {
            return []
        }
        current = current[chunk]
    }

    const items = Array.isArray(current) ? current : [current]

    return items
        .filter(i => !!i)
        .map(objectCallback)
}

export const intOrNull = (value: any): number|null => value ? parseInt(value) : null
export const strToBool = (value: string|null): boolean => value === 'true'
export const boolToNum = (value: boolean|number): number => value ? 1 : 0
export const strToBoolNum = (value: string|null): number => boolToNum(strToBool(value))