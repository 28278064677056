import AbstractEntity from "./AbstractEntity";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import UserQuestionnaireTopic from "@/api/types/UserQuestionnaireTopic";

const TABLE_NAME = 'UserQuestionnaireTopic'

export default class UserQuestionnaireTopicEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'pk', autoIncrement: true }, [
            'pk',
            'userQuestionnairePk',
            'topicId',
        ])
    }

    public static async persist(db: IDBDatabase, topic: UserQuestionnaireTopic) {
        const data = super.getPersistData<QuestionnaireTopic>(topic, [
            'userQuestionnairePk',
            'topicId',
            'progress',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }

    static async findByTopicId(db: IDBDatabase, userQuestionnairePk: number, topicId: number) {
        const res = await this._find(
            db,
            TABLE_NAME,
            topicId,
            'topicId',
            (item: UserQuestionnaireTopic) => item.userQuestionnairePk === userQuestionnairePk,
        )

        return res ? res[0] : null
    }
}