import User from "../types/User";
import ResponseInterface from "./ResponseInterface";

export default class LoginResponse implements ResponseInterface {
    public sessionId: string|null = null
    public user: User|null = null

    isXmlResponse(): boolean {
        return true
    }

    parse(data: { loginResponse: LoginResponse }): void {
        this.sessionId = data.loginResponse.sessionId
        this.user = new User(data.loginResponse.user)
        console.log('login response', data.loginResponse, this.user)
    }

    setBlob(blob: Blob): void {}
}