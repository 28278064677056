import UserQuestionnaire from "../types/UserQuestionnaire";
import AbstractType from "@/api/types/AbstractType";
import {extractArrayValues} from "@/api/lib";

export default class QuestionnaireRelease extends AbstractType {
    public id: number
    public duration: number|null
    public durationUnit: number|null
    public fromDate: string
    public interval: number|null
    public intervalUnit: number|null
    public mandatory: boolean
    public questionnaireId: number
    public releaseToDate: string|null
    public reminderDays: number|null
    public state: number
    public thresholdAnalysis: number
    public toDate: string|null
    public type: number
    public questionnaires: UserQuestionnaire[]

    constructor(data: any) {
        super()
        this.id = parseInt(data.id)
        this.duration = data.duration ? parseInt(data.duration) : null
        this.durationUnit = data.durationUnit ? parseInt(data.durationUnit) : null
        this.fromDate = data.fromDate
        this.interval = data.interval ? parseInt(data.interval) : null
        this.intervalUnit = data.intervalUnit ? parseInt(data.intervalUnit) : null
        this.mandatory = data.mandatory === 'true'
        this.questionnaireId = parseInt(data.questionnaireId)
        this.releaseToDate = data.releaseToDate || null
        this.reminderDays = data.reminderDays ? parseInt(data.reminderDays) : null
        this.state = parseInt(data.state)
        this.thresholdAnalysis = parseInt(data.thresholdAnalysis)
        this.toDate = data.toDate || null
        this.type = parseInt(data.type)

        this.questionnaires = extractArrayValues(data, 'userQuestionnaires.userQuestionnaire', (q: any) => new UserQuestionnaire(q))
    }
}