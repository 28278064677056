import UserAnswer from "./UserAnswer";
import {extractArrayValues, intOrNull} from "@/api/lib";

export default class UserQuestion {
    public pk: number|null
    public id: number|null
    public userQuestionnaireId: number|null
    public userQuestionnairePk: number|null
    public questionId: number
    public stringValue: string|null
    public numberValue: number|null
    public dateValue: string|null
    public answers: UserAnswer[]
    public dirty: number|boolean

    constructor(data: any) {
        this.pk = intOrNull(data.pk)
        this.id = intOrNull(data.id)
        this.userQuestionnaireId = intOrNull(data.userQuestionnaireId)
        this.userQuestionnairePk = intOrNull(data.userQuestionnairePk)
        this.questionId = parseInt(data.questionId)
        this.stringValue = data.stringValue || null
        this.numberValue = intOrNull(data.numberValue)
        this.dateValue = data.dateValue || null
        this.dirty = typeof data.dirty !== 'undefined' ? data.dirty : false

        this.answers = extractArrayValues(
            data,
            'answers.userAnswer',
            (a: any) => new UserAnswer(a))
    }
}