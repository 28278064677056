import ResponseInterface from "./ResponseInterface";
import Questionnaire from "../types/Questionnaire";

export default class GetQuestionnairesResponse implements ResponseInterface {
    public questionnaires: Questionnaire[] = []

    isXmlResponse(): boolean {
        return true
    }

    parse(data: any): void {
        for (const release of data.collection.questionnaire) {
            this.questionnaires.push(new Questionnaire(release))
        }
    }

    setBlob(blob: Blob): void {}
}