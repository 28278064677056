export default class AnswerDependency {
    public id: number
    public questionId: number
    public answerId: number

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.questionId = parseInt(data.questionId)
        this.answerId = parseInt(data.answerId)
    }
}