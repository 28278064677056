import {createRouter, createWebHashHistory} from 'vue-router'
import HomeActivity from "@/views/HomeActivity.vue";
import LoginActivity from "@/views/LoginActivity.vue";
import {useStore} from "vuex";
import MenuActivity from "@/views/MenuActivity.vue";
import QuestionnaireOverviewActivity from "@/views/QuestionnaireOverviewActivity.vue";
import CategoryFilterActivity from "@/views/CategoryFilterActivity.vue";
import TopicOverviewActivity from "@/views/TopicOverviewActivity.vue";
import AnalyzeComparisonChoiceActivity from "@/views/AnalyzeComparisonChoiceActivity.vue";
import OptimizationActivity from "@/views/OptimizationActivity.vue";
import CheckActivity from "@/views/CheckActivity.vue";
import ProfileActivity from "@/views/ProfileActivity.vue";

const routes = [
  {
    path: '/',
    name: 'home',
    component: HomeActivity
  },
  {
    path: '/login',
    name: 'login',
    component: LoginActivity
  },
  {
    path: '/menu',
    name: 'menu',
    component: MenuActivity
  },
  {
    path: '/questionnaire/:mode',
    name: 'questionnaire',
    component: QuestionnaireOverviewActivity
  },
  {
    path: '/questionnaire/:mode/category',
    name: 'categoryFilter',
    component: CategoryFilterActivity
  },
  {
    path: '/questionnaire/:mode/topic',
    name: 'topicOverview',
    component: TopicOverviewActivity
  },
  {
    path: '/questionnaire/:mode/choice',
    name: 'comparisonChoice',
    component: AnalyzeComparisonChoiceActivity
  },
  {
    path: '/questionnaire/:mode/optimization',
    name: 'optimization',
    component: OptimizationActivity
  },
  {
    path: '/check',
    name: 'check',
    component: CheckActivity
  },
  {
    path: '/profile',
    name: 'profile',
    component: ProfileActivity
  },
  {
    path: '/signup',
    name: 'signup',
    component: ProfileActivity
  },
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes,
})

router.beforeEach((to, from, next) => {
  const store = useStore()

  // @ts-ignore
  if (store.state.initialized && !store.state.configuration && !['home', 'login', 'signup'].includes(to.name)) {
    console.log('return to login', to.name)
    next({ name: 'login' })
    return
  }

  next()
})

export default router
