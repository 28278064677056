import ResponseInterface from "@/api/responses/ResponseInterface";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";

export default class GetReleasesResponse implements ResponseInterface{
    public releases: QuestionnaireRelease[] = []

    isXmlResponse(): boolean {
        return true
    }

    parse(data: any): void {
        for (const release of data.collection.questionnaireRelease) {
            this.releases.push(new QuestionnaireRelease(release))
        }
    }

    setBlob(blob: Blob): void {}
}