export default class AddOn {
    public id: number
    public questionId: number
    public type: number
    public value: string

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.questionId = parseInt(data.questionId)
        this.type = parseInt(data.type)
        this.value = data.value
    }
}