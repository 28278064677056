import AbstractEntity from "./AbstractEntity";
import DependencyQuestionAnswer from "@/api/types/DependencyQuestionAnswer";
import QuestionDependency from "@/api/types/QuestionDependency";

const TABLE_NAME = 'DependencyQuestionAnswer'

export default class DependencyQuestionAnswerEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'answerId',
            'questionDependencyId'
        ])
    }

    public static async persist(db: IDBDatabase, dependencyQuestionAnswer: DependencyQuestionAnswer) {
        const data = super.getPersistData<DependencyQuestionAnswer>(dependencyQuestionAnswer, [
            'id',
            'answerId',
            'questionDependencyId',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    public static async findForQuestionDependency(db: IDBDatabase, id: number) {
        return this._find<DependencyQuestionAnswer>(
            db,
            TABLE_NAME,
            id,
            'questionDependencyId',
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}