<template>
  <app-activity title="Risiko - Analyse" id="category-filter-activity" :icons="icons" @icon-click="onIconClick">
    <header>Vergleiche anzeigen</header>

    <ul class="questionnaire-list">
      <li v-for="(name, id) in items" :key="id">
        <div class="row-level2">
          <div class="radio">
            <input type="radio" :id="`item-${id}`" :value="parseInt('' + id)" v-model="selectedValue">
          </div>
          <label :for="`item-${id}`" class="name">{{ name }}</label>
        </div>
      </li>
    </ul>
  </app-activity>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import AppActivity from "@/components/AppActivity.vue";

import {ICON_CANCEL, ICON_OK} from "@/lib/icons";
import {useRouter} from "vue-router";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import {ref} from "vue";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import getDb from "@/db/Database";
import ReportRequest from "@/api/requests/ReportRequest";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import GetReportResponse from "@/api/responses/GetReportResponse";
import {hideSpinner, showAlert, showSpinner} from "@/store";
import {useApiClient} from "@/api";

const icons = [
  ICON_OK,
  ICON_CANCEL
]

const store = useStore()
const router = useRouter()
const api = useApiClient()

const items = {
  1: 'Eigene Entwicklung',
  // 2: 'Mit Betrieben vergleichen',
  3: 'Ohne Vergleich',
}

const selectedTopics = store.state.selectedTopics

const selectedValue = ref<number|null>(null)

const userQuestionnaire: UserQuestionnaire = store.state.currentQuestionnaire
const release: QuestionnaireRelease = store.state.currentRelease

const onIconClick = async (icon: string) => {
  switch (icon) {
    case ICON_OK:
      if (selectedValue.value) {
        await (async () => {
          showSpinner()

          const request = new ReportRequest(1, userQuestionnaire.description, <number>userQuestionnaire.id)
          request.comparison = selectedValue.value
          request.topics = selectedTopics.map((topic: QuestionnaireTopic) => topic.id)

          api.createReport(store.state.configuration.sessionId, request)
              .then(async (res: GetReportResponse) => {
                try {
                  await res.openFile()
                } catch (error: any) {
                  showAlert('Fehler: ' + error.message)
                }
                hideSpinner()
              })
              .catch(err => {
                console.error('ERROR', err)
                hideSpinner()
              })
        })()
      }
      break
    case ICON_CANCEL:
      store.commit('SET_QUESTIONNAIRE', { questionnaire: null, release: null })
      store.commit('SET_SELECTED_TOPICS', [])
      await router.push({ name: 'menu' })
      break
  }
}
</script>

<style lang="scss">
#category-filter-activity {
  header {
    background: var(--questionnaire-header-color);
    font-size: var(--content-header-font-size);
    font-weight: 500;
    text-align: center;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-top: -5px;
    margin-bottom: 15px;
  }
}
</style>