export default class ReportRequest {
    public reportType: number;
    public reportName: string
    public userQuestionnaireId: number|null = null;
    public comparison: number|null = null;
    public topics: number[]|null = null

    constructor(reportType: number, reportName: string, userQuestionnaireId: number) {
        this.reportType = reportType
        this.reportName = reportName
        this.userQuestionnaireId = userQuestionnaireId
    }
}