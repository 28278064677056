import {Module} from "vuex";
import Question from "@/api/types/Question";
import UserQuestion from "@/api/types/UserQuestion";
import {QuestionEntry} from "@/lib/QuestionHandler";

type State = {
    categoryFilter: number[]
    questions: QuestionEntry[]
    currentQuestion: Question|null
    currentUserQuestion: UserQuestion|null
    questionIndex: number
}

const check: Module<State, any> = {
    namespaced: true,

    state: {
        categoryFilter: [],
        questions: [],
        currentQuestion: null,
        currentUserQuestion: null,
        questionIndex: 0
    },

    mutations: {
        SET_CATEGORIES: (state: State, categoryIds: number[]) => {
            state.categoryFilter = categoryIds
        },
        CLEAR: (state: State) => {
            state.questions = []
            state.currentQuestion = null
            state.currentUserQuestion = null
            state.questionIndex = 0
        },
        SET_QUESTIONS: (state: State, questions: QuestionEntry[]) => {
            state.questions = questions
        },
        SET_CURRENT_QUESTION: (state: State, question: Question|null) => {
            state.currentQuestion = question
        },
        SET_CURRENT_USER_QUESTION: (state: State, userQuestion: UserQuestion|null) => {
            state.currentUserQuestion = userQuestion
        },
        SET_QUESTION_INDEX: (state: State, index: number) => {
            state.questionIndex = index
        },
    }
}

export default check