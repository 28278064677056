import QuestionnaireReleaseEntity from "@/db/QuestionnaireReleaseEntity";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import ConfigurationEntity from "@/db/ConfigurationEntity";
import QuestionnaireEntity from "@/db/QuestionnaireEntity";
import QuestionEntity from "@/db/QuestionEntity";
import QuestionnaireTopicEntity from "@/db/QuestionnaireTopicEntity";
import QuestionnaireCategoryEntity from "@/db/QuestionnaireCategoryEntity";
import AddOnEntity from "@/db/AddOnEntity";
import AnswerEntity from "@/db/AnswerEntity";
import AnswerDependencyEntity from "@/db/AnswerDependencyEntity";
import QuestionDependencyEntity from "@/db/QuestionDependencyEntity";
import DependencyQuestionAnswerEntity from "@/db/DependencyQuestionAnswerEntity";
import UserQuestionEntity from "@/db/UserQuestionEntity";
import UserAnswerEntity from "@/db/UserAnswerEntity";
import UserQuestionnaireTopicEntity from "@/db/UserQuestionnaireTopicEntity";
import UserQuestionnaireCategoryEntity from "@/db/UserQuestionnaireCategoryEntity";
import MembershipEntity from "@/db/Membersip";

let db: IDBDatabase|null = null

export const initDb = async (version: number) => {
    if (db) {
        throw new Error('database already initialized')
    }

    return new Promise<IDBDatabase>((resolve, reject) => {
        const request = indexedDB.open('risikoDB', version)

        request.onupgradeneeded = (event: IDBVersionChangeEvent) => {
            ConfigurationEntity.upgrade(request.result, event)
            QuestionnaireReleaseEntity.upgrade(request.result, event)
            UserQuestionnaireEntity.upgrade(request.result, event)
            UserQuestionEntity.upgrade(request.result, event)
            UserAnswerEntity.upgrade(request.result, event)
            UserQuestionnaireTopicEntity.upgrade(request.result, event)
            UserQuestionnaireCategoryEntity.upgrade(request.result, event)
            QuestionnaireEntity.upgrade(request.result, event)
            QuestionnaireTopicEntity.upgrade(request.result, event)
            QuestionnaireCategoryEntity.upgrade(request.result, event)
            QuestionEntity.upgrade(request.result, event)
            AddOnEntity.upgrade(request.result, event)
            AnswerEntity.upgrade(request.result, event)
            AnswerDependencyEntity.upgrade(request.result, event)
            QuestionDependencyEntity.upgrade(request.result, event)
            DependencyQuestionAnswerEntity.upgrade(request.result, event)
            MembershipEntity.upgrade(request.result, event)
        }

        request.onerror = (event) => {
            console.error(event)

            // @ts-ignore
            const error: { code: number, message: string } = event.target?.error

            reject(error)
        }

        request.onsuccess = (event) => {
            db = request.result

            resolve(db)
        }
    })
}

export const clearDb = async (keepConfiguration: boolean = false): Promise<boolean> => {
    if (!db) {
        throw new Error('database not initialized')
    }

    try {
        let promises = []

        if (!keepConfiguration) {
            promises.push(ConfigurationEntity.clear(db))
        }

        promises = [
            ...promises,
            QuestionnaireReleaseEntity.clear(db),
            UserQuestionnaireEntity.clear(db),
            UserQuestionEntity.clear(db),
            UserAnswerEntity.clear(db),
            UserQuestionnaireTopicEntity.clear(db),
            UserQuestionnaireCategoryEntity.clear(db),
            QuestionnaireEntity.clear(db),
            QuestionnaireTopicEntity.clear(db),
            QuestionnaireCategoryEntity.clear(db),
            QuestionEntity.clear(db),
            AddOnEntity.clear(db),
            AnswerEntity.clear(db),
            AnswerDependencyEntity.clear(db),
            QuestionDependencyEntity.clear(db),
            DependencyQuestionAnswerEntity.clear(db),
            MembershipEntity.clear(db),
        ]

        await Promise.all(promises)
    } catch (err) {
        console.error(err)
        return false
    }
    return true
}

const getDb = (): IDBDatabase => {
    if (!db) {
        throw new Error('database not initialized')
    }

    return db
}

export default getDb