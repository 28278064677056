import {intOrNull} from "@/api/lib";

export default class UserAnswer {
    public pk: number|null
    public id: number|null
    public answerId: number
    public questionId: number
    public userQuestionPk: number|null
    public dirty: number|boolean

    constructor(data: any) {
        this.pk = intOrNull(data.pk)
        this.id = intOrNull(data.id)
        this.answerId = parseInt(data.answerId)
        this.questionId = parseInt(data.questionId)
        this.userQuestionPk = intOrNull(data.userQuestionPk)
        this.dirty = typeof data.dirty !== 'undefined' ? data.dirty : false
    }
}