import AbstractEntity from "./AbstractEntity";
import Questionnaire from "@/api/types/Questionnaire";
import QuestionnaireTopicEntity from "@/db/QuestionnaireTopicEntity";
import QuestionnaireCategoryEntity from "@/db/QuestionnaireCategoryEntity";
import QuestionEntity from "@/db/QuestionEntity";

const TABLE_NAME = 'Questionnaire'

export default class QuestionnaireEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id'
        ])
    }

    public static async persist(db: IDBDatabase, questionnaire: Questionnaire) {
        const data = super.getPersistData<Questionnaire>(questionnaire, [
            'id',
            'name',
            'description',
            'state',
        ])

        await super._put(db, TABLE_NAME, data)

        for (const topic of questionnaire.topics) {
            await QuestionnaireTopicEntity.persist(db, topic)
        }

        for (const category of questionnaire.categories) {
            await QuestionnaireCategoryEntity.persist(db, category)
        }

        for (const question of questionnaire.questions) {
            await QuestionEntity.persist(db, question)
        }
    }

    static async find(db: IDBDatabase, id: number): Promise<Questionnaire|null> {
        return super._findOne(db, TABLE_NAME, id)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}