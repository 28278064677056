import AbstractType from "@/api/types/AbstractType";

export default abstract class AbstractEntity {
    public static getPersistData<T>(obj: AbstractType, keySet: string[]): T {
        // @ts-ignore
        return Object.keys(obj)
            .filter((key: string) => keySet.includes(key))
            .reduce((res: { [key: string]: any }, key) => {
                // @ts-ignore
                res[key] = obj[key]
                return res
            }, {})
    }

    protected static _upgrade(db: IDBDatabase, event: IDBVersionChangeEvent, tableName: string, options: IDBObjectStoreParameters, indices: string[] = []) {
        let objectStore: IDBObjectStore
        try {
            // @ts-ignore
            objectStore = event.currentTarget.transaction.objectStore(tableName)
            // console.debug(`table ${tableName} already exists`)
        } catch (err: any) {
            objectStore = db.createObjectStore(tableName, options)
            // console.log(`created table ${tableName}`)
        }

        for (const indexName of objectStore.indexNames) {
            objectStore.deleteIndex(indexName)
        }

        for (const index of indices) {
            objectStore.createIndex(index, index)
        }
    }

    protected static async _put(db: IDBDatabase, tableName: string, data: AbstractType) {
        return new Promise<any>((resolve, reject) => {
            console.log('PUT', tableName, data)
            const request = db.transaction(tableName, 'readwrite')
                .objectStore(tableName)
                .put(data)

            request.onerror = (err: any) => {
                reject(err)
            }

            request.onsuccess = () => {
                // console.debug('DB', 'persisted', tableName + ':', request.result)
                resolve(request.result)
            }
        })
    }

    protected static async _getAll<T extends AbstractType>(db: IDBDatabase, tableName: string): Promise<T[]> {
        return new Promise((resolve, reject) => {
            const request = db.transaction(tableName)
                .objectStore(tableName)
                .getAll()

            request.onerror = (err: any) => {
                reject(err)
            }

            request.onsuccess = () => {
                const rows: T[] = request.result
                if (rows) {
                    resolve(rows)
                }
                resolve([])
            }
        })
    }

    protected static async _findOne<T extends AbstractType>(
        db: IDBDatabase,
        tableName: string,
        id: number,
        key: string = 'id',
        filterCallback: ((item: T) => boolean)|null = null
    ): Promise<T|null> {
        return new Promise((resolve, reject) => {
            const store = db.transaction(tableName)
                .objectStore(tableName)
            const index = store.index(key)
            const cursorRequest = index.openCursor(IDBKeyRange.only(id))

            cursorRequest.onerror = (err: any) => {
                reject(err)
            }

            cursorRequest.onsuccess = (ev) => {
                // @ts-ignore
                const cursor = ev.target?.result
                if (cursor) {
                    if (filterCallback && !filterCallback(cursor.value)) {
                        cursor.continue()
                    } else {
                        resolve(cursor.value)
                    }
                } else {
                    resolve(null)
                }
            }
        })
    }

    protected static async _find<T extends AbstractType>(
        db: IDBDatabase,
        tableName: string,
        id: number,
        key: string = 'id',
        filterCallback: ((item: T) => boolean)|null = null
    ): Promise<T[]> {
        return new Promise((resolve, reject) => {
            const store = db.transaction(tableName)
                .objectStore(tableName)
            const index = store.index(key)
            const cursorRequest = index.openCursor(IDBKeyRange.only(id))

            cursorRequest.onerror = (err: any) => {
                reject(err)
            }

            const res: T[] = []
            cursorRequest.onsuccess = (ev) => {
                // @ts-ignore
                const cursor = ev.target?.result
                if (cursor) {
                    if (!filterCallback || filterCallback(cursor.value)) {
                        res.push(cursor.value)
                    }
                    cursor.continue()
                } else {
                    resolve(res)
                }
            }
        })
    }

    protected static async _delete<T extends AbstractType>(
        db: IDBDatabase,
        tableName: string,
        id: number,
        key: string = 'id',
        filterCallback: ((item: T) => boolean)|null = null
    ): Promise<void> {
        return new Promise((resolve, reject) => {
            try {
                const store = db.transaction(tableName, 'readwrite')
                    .objectStore(tableName)
                const index = store.index(key)
                const cursorRequest = index.openCursor(IDBKeyRange.only(id))

                cursorRequest.onerror = (err: any) => {
                    reject(err)
                }

                const res: T[] = []
                cursorRequest.onsuccess = (ev) => {
                    // @ts-ignore
                    const cursor = ev.target?.result
                    if (cursor) {
                        cursor.delete()
                        cursor.continue()
                    } else {
                        resolve()
                    }
                }
            } catch (e) {
                console.error(e)
                resolve()
            }
        })
    }

    protected static async _update<T extends AbstractType>(
        db: IDBDatabase,
        tableName: string,
        id: number,
        key: string = 'id',
        data: any,
    ): Promise<T|null> {
        return new Promise(async (resolve, reject) => {
            let item = await this._findOne(db, tableName, id, key)

            if (item) {
                const store = db.transaction(tableName, 'readwrite')
                    .objectStore(tableName)

                const newItem = { ...item, ...data }
                const updateRequest = store.put(newItem)

                updateRequest.onerror = (err) => reject(err)
                updateRequest.onsuccess = () => resolve(newItem)
            }
        })
    }

    protected static async _getFirst<T extends AbstractType>(db: IDBDatabase, tableName: string): Promise<T|null> {
        const entries: T[]|null = await this._getAll<T>(db, tableName)
        return entries ? entries[0] : null
    }

    static async clear(db: IDBDatabase, tableName: string): Promise<void> {
        return new Promise((resolve, reject) => {
            const request = db.transaction(tableName, 'readwrite')
                .objectStore(tableName)
                .clear()

            request.onerror = (err: any) => {
                reject(err)
            }

            request.onsuccess = () => {
                resolve()
            }
        })
    }
}