import AbstractEntity from "./AbstractEntity";
import AnswerDependency from "@/api/types/AnswerDependency";

const TABLE_NAME = 'AnswerDependency'

export default class AnswerDependencyEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionId',
        ])
    }

    public static async persist(db: IDBDatabase, answerDependency: AnswerDependency) {
        const data = super.getPersistData<AnswerDependency>(answerDependency, [
            'id',
            'questionId',
            'answerId',
            'type',
            'value',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async findForQuestion(db: IDBDatabase, questionId: number) {
        return this._find<AnswerDependency>(
            db,
            TABLE_NAME,
            questionId,
            'questionId',
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}