export default class DependencyQuestionAnswer {
    public id: number
    public answerId: number
    public questionDependencyId: number

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.answerId = parseInt(data.answerId)
        this.questionDependencyId = parseInt(data.questionDependencyId)
    }
}