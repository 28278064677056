<template>
  <div class="spinner-wrapper" v-if="show">
    <app-spinner-icon/>
  </div>
</template>

<script lang="ts" setup>
import AppSpinnerIcon from "@/components/AppSpinnerIcon.vue";
import {useStore} from "vuex";
import {computed, watch} from "vue";

const store = useStore()

const show = computed(() => store.state.showSpinner)

const disableBackCallback = (e: any) => e.preventDefault()

watch(show, () => {
  if (show.value) {
    document.addEventListener('backbutton', disableBackCallback)
  } else {
    document.removeEventListener('backbutton', disableBackCallback)
  }
}, { immediate: true })
</script>

<style lang="scss">
.spinner-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 999;

  .lds-ring {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}
</style>
