<template>
  <div>
    <div class="app-progress-bar" ref="outer">
      <div class="inner" ref="inner"></div>
    </div>
    <div class="app-progress-text">
      <div class="left">0%</div>
      <div class="center">{{ modelValue }}%</div>
      <div class="right">100%</div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {onBeforeUnmount, onMounted, ref, watch} from "vue";

const props = defineProps<{
  modelValue: number
}>()

const outer = ref<HTMLDivElement>()
const inner = ref<HTMLDivElement>()

const update = () => {
  if (inner.value && outer.value) {
    const fullWidth = outer.value.getBoundingClientRect().width
    const width = Math.round(fullWidth * props.modelValue / 100)
    inner.value.style.width = `${width}px`
  }
}

onMounted(() => update())

watch(() => props.modelValue, update)

window.addEventListener('resize', update)

onBeforeUnmount(() => window.removeEventListener('resize', update))
</script>

<style lang="scss">
.app-progress-bar {
  width: calc(100% - 30px);
  height: 20px;
  margin: 8px 15px 0;
  background: #dddddd;

  .inner {
    background: var(--form-base-color);
    height: 100%;
    transition: width .3s;
  }
}
.app-progress-text {
  display: flex;
  justify-content: space-between;
  margin: 5px 15px 0;
  font-size: 10pt;
  color: #8b8b8b;
}
</style>