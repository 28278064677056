<template>
  <app-activity title="Risiko - Analyse" :icons="icons" @icon-click="onIconAction" id="check-activity">
    <div class="location-header" v-if="currentIndex >= 0">
      {{ `${currentLocation.name} - ${currentLocation.registrationNr}` }}
    </div>

    <result-screen :completed="progress === 100" v-if="currentIndex < 0">
      <template #navigation>
        <label class="checkbox check-only-open" v-if="currentIndex >= 0">
          <input type="checkbox" v-model="showOnlyOpenQuestions">
          Nur unbeantwortete Fragen anzeigen
        </label>
        <nav>
          <div class="prev" :class="{ disabled: prevIndex < 0 && currentIndex >= 0 }">
            <a href="javascript:void(0)" @click="prevQuestion">
              <img :src="ICON_BACK"/>
            </a>
          </div>
          <div class="app-progress">
            <progress-bar v-model="progress" v-if="currentIndex >= 0"/>
          </div>
          <div class="next">
            <a href="javascript:void(0)" @click="nextQuestion" v-if="currentIndex >= 0">
              <img :src="ICON_NEXT"/>
            </a>
          </div>
        </nav>
      </template>
    </result-screen>

    <question-component
        :question="currentQuestion"
        v-model="rawAnswer"
        v-if="currentQuestion">
      <template #navigation>
        <nav style="margin-top: -50px">
          <div class="prev" :class="{ disabled: prevIndex < 0 && currentIndex >= 0 }">
            <a href="javascript:void(0)" @click="prevQuestion">
              <img :src="ICON_BACK"/>
            </a>
          </div>
          <div class="next">
            <a href="javascript:void(0)" @click="nextQuestion" v-if="currentIndex >= 0">
              <img :src="ICON_NEXT"/>
            </a>
          </div>
        </nav>
      </template>
    </question-component>

    <template #footer>
      <label class="checkbox check-only-open" v-if="currentIndex >= 0">
        <input type="checkbox" v-model="showOnlyOpenQuestions">
        Nur unbeantwortete Fragen anzeigen
      </label>
      <nav>
        <div class="app-progress">
          <progress-bar v-model="progress" v-if="currentIndex >= 0"/>
        </div>
      </nav>
    </template>

    <app-alert
      :show="showOpenQuestionsModal"
      title="Offene Fragen"
      class="open-questions-alert"
      @ok="() => showOpenQuestionsModal = false"
    >
      <div class="title">Offene Fragen</div>
      <ul>
        <li v-for="question of openQuestions" :key="question.id" @click="() => gotoQuestion(question.id)">
          Frage {{ question.numberString }}
        </li>
      </ul>
    </app-alert>
  </app-activity>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import AppActivity from "@/components/AppActivity.vue";
import getDb from "@/db/Database";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import ConfigurationEntity from "@/db/ConfigurationEntity";
import {ICON_BACK, ICON_CANCEL, ICON_LIST, ICON_NEXT, ICON_SAVE} from "@/lib/icons";
import {useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import QuestionComponent from "@/components/question/QuestionComponent.vue";
import ProgressBar from "@/components/ProgressBar.vue";
import QuestionHandler, {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";
import {hideSpinner, showSpinner} from "@/store";
import Location from "@/api/types/Location";
import AppAlert from "@/components/AppAlert.vue";
import ResultScreen from "@/components/check/ResultScreen.vue";

const icons = computed(() => {
  const res = [ICON_LIST]

  if (currentIndex.value >= 0) {
    res.push(ICON_SAVE)
  }

  res.push(ICON_CANCEL)
  return res
})

const store = useStore()
const router = useRouter()

const onIconAction = async (icon: string) => {
  switch (icon) {
    case ICON_LIST:
      showOpenQuestionsModal.value = true
      break
    case ICON_SAVE:
      if (currentIndex.value >= 0) {
        await handler.saveAnswer(currentIndex.value, rawAnswer.value)
      }
      await store.dispatch('SAVE_QUESTIONNAIRE')
      await router.push({ name: 'questionnaire', params: { mode: 'check' } })
      break
    case ICON_CANCEL:
      handler.clear()
      await router.push({ name: 'menu' })
      break
  }
}

const handler = new QuestionHandler(store, getDb())

const showOnlyOpenQuestions = ref<boolean>(false)

const rawAnswer = ref<RawAnswer>(null)

const categoryFilter = store.state.check.categoryFilter
const currentRelease: QuestionnaireRelease = store.state.currentRelease
const currentQuestionnaire: UserQuestionnaire = store.state.currentQuestionnaire

const currentLocation = ref<Location|null>(store.getters.selectedLocation)

const globalQuestions = computed(() => store.state.check.questions)
const openQuestions = ref([])

const currentIndex = computed<number>(() => store.state.check.questionIndex)
const currentQuestion = ref<QuestionEntry>()

const showOpenQuestionsModal = ref<boolean>(false)

const prevIndex = ref<number>(-1)
const nextIndex = ref<number>(-1)

const progress = ref<number>(0)

const isResultPage = computed(() => currentIndex.value === -1)

const prevQuestion = async () => {
  if (prevIndex.value >= 0) {
    if (currentIndex.value >= 0) {
      await handler.saveAnswer(currentIndex.value, rawAnswer.value)
    }
    const { prev, next } = detectNeighbors()
    store.commit('check/SET_QUESTION_INDEX', prev)
  }
}

const nextQuestion = async () => {
  if (nextIndex.value >= 0) {
    await handler.saveAnswer(currentIndex.value, rawAnswer.value)
    const { prev, next } = detectNeighbors()
    store.commit('check/SET_QUESTION_INDEX', next)
  } else {
    showOnlyOpenQuestions.value = false
    await handler.saveAnswer(currentIndex.value, rawAnswer.value)
    store.commit('check/SET_QUESTION_INDEX', -1)
  }
}

const load = async () => {
  const configuration = await ConfigurationEntity.get(getDb())

  if (configuration) {
    showSpinner()
    await handler.loadQuestions(
        <number>currentRelease.questionnaireId,
        <number>currentQuestionnaire.pk,
        configuration.user,
        categoryFilter)
    hideSpinner()
  }
}

const calculateProgress = () => {
  let answered = 0
  let count = 0

  for (const question of store.state.check.questions) {
    if (question.displayed) {
      count++
      const uq = question.userQuestion
      if (uq?.answers?.length || uq?.stringValue || uq?.numberValue || uq?.dateValue) {
        answered++
      }
    }
  }

  progress.value = count
      ? Math.round(answered * 100 / count)
      : 0
}

const ignoreIfAnswered = ((question: any, ignoreShowOnlyOpenQuestions: boolean = false) => {
  if (question && question.userQuestion && (ignoreShowOnlyOpenQuestions || showOnlyOpenQuestions.value)) {
    const q = question.userQuestion
    return (q.stringValue || q.numberValue || q.dateValue || q.answers?.length)
  }
  return false
})

const getLastQuestionIndex = (): number => {
  return globalQuestions.value.length
}

const detectNeighbors = (): { prev: number, next: number } => {
  currentQuestion.value = globalQuestions.value[currentIndex.value]

  let prevIndexNum = currentIndex.value >= 0 ? currentIndex.value : getLastQuestionIndex()
  do {
    prevIndexNum --
  } while (prevIndexNum >= 0 && (typeof globalQuestions.value[prevIndexNum] === 'undefined' || !globalQuestions.value[prevIndexNum].displayed || ignoreIfAnswered(globalQuestions.value[prevIndexNum])))

  let nextIndexNum = currentIndex.value >= 0 ? currentIndex.value : getLastQuestionIndex()
  do {
    nextIndexNum ++
    if (typeof globalQuestions.value[nextIndexNum] === 'undefined') {
      nextIndexNum = -1
      break
    }
  } while (globalQuestions.value[nextIndexNum] && (!globalQuestions.value[nextIndexNum].displayed || ignoreIfAnswered(globalQuestions.value[nextIndexNum])))

  return {
    prev: prevIndexNum >= 0 ? prevIndexNum : -1,
    next: typeof globalQuestions.value[nextIndexNum] !== 'undefined' ? nextIndexNum : -1
  }
}

watch(() => ({ index: currentIndex.value, globalQuestions, showOnlyOpenQuestions: showOnlyOpenQuestions.value  }), () => {
  const { prev, next } = detectNeighbors()

  openQuestions.value = globalQuestions.value
      .filter((q: any) => !ignoreIfAnswered(q, true))
      .filter((q: any) => q.displayed)

  prevIndex.value = prev
  nextIndex.value = next

  calculateProgress()
}, { immediate: true, deep: true })

const gotoQuestion = async (id: number) => {
  const index = globalQuestions.value.findIndex((q: any) => q.id === id)
  if (index >= 0) {
    await handler.saveAnswer(currentIndex.value, rawAnswer.value)
    store.commit('check/SET_QUESTION_INDEX', index)
    showOpenQuestionsModal.value = false
  }
}

load()
</script>

<style lang="scss">
#check-activity {
  .location-header {
    background: #cbcbcb;
    padding: 0 2px;
    margin-top: -5px;
    margin-bottom: 20px;
  }

  footer {
    background: #fff;
  }

  footer, .navigation {
    padding: 15px;

    nav {
      display: flex;
      justify-content: space-between;
      padding: 0;
      margin-top: 5px;

      .prev, .next {
        display: flex;
        flex-direction: column;
        justify-content: center;

        &.disabled {
          opacity: .5;
          pointer-events: none;
        }
      }

      .app-progress {
        width: 100%;
      }
    }
    img {
      height: 37pt;
    }
  }

  .open-questions-alert {
    ul {
      list-style-type: none;
      margin: 10px 0 0;
      padding: 0;
    }

    .title {
      text-align: center;
      font-weight: 500;
      background: var(--question-question-background);
      font-size: 18pt;
    }
  }
}
</style>
