export const ICON_EXIT = require('@/assets/icons/exit.png')
export const ICON_ADD = require('@/assets/icons/add.png')
export const ICON_ARBEITSPLAN_KUPIERVERZICHT = require('@/assets/icons/arbeitsplan_kupierverzicht.png')
export const ICON_ARROW_DOWN = require('@/assets/icons/arrow_down.png')
export const ICON_ARROW_RIGHT = require('@/assets/icons/arrow_right.png')
export const ICON_BACK = require('@/assets/icons/back.png')
export const ICON_BANNER = require('@/assets/icons/banner.png')
export const ICON_CANCEL = require('@/assets/icons/cancel.png')
export const ICON_CHECKBOX_CHECKED = require('@/assets/icons/checkbox_checked.png')
export const ICON_CHECKBOX_UNCHECKED = require('@/assets/icons/checkbox_unchecked.png')
export const ICON_CONFIGURE = require('@/assets/icons/configure.png')
export const ICON_FORMULAR_ICON = require('@/assets/icons/formular_icon.png')
export const ICON_INFO = require('@/assets/icons/info.png')
export const ICON_INFORMATION_ICON = require('@/assets/icons/information_icon.png')
export const ICON_LIST = require('@/assets/icons/list.png')
export const ICON_LOGO_GEMA_1 = require('@/assets/icons/logo_gema_1.png')
export const ICON_MAIL = require('@/assets/icons/mail.png')
export const ICON_NEXT = require('@/assets/icons/next.png')
export const ICON_OK = require('@/assets/icons/ok.png')
export const ICON_OPTIMIZE = require('@/assets/icons/optimize.png')
export const ICON_OPTIMZE_ALT = require('@/assets/icons/optimze_alt.png')
export const ICON_OPTIMZE_ORIGINAL = require('@/assets/icons/optimze_original.png')
export const ICON_PRINTER = require('@/assets/icons/printer.png')
export const ICON_RADIO_CHECKED = require('@/assets/icons/radio_checked.png')
export const ICON_RADIO_UNCHECKED = require('@/assets/icons/radio_unchecked.png')
export const ICON_REFRESH = require('@/assets/icons/refresh.png')
export const ICON_REPORT_ICON = require('@/assets/icons/report_icon.png')
export const ICON_SAVE = require('@/assets/icons/save.png')
export const ICON_SPP_SYMBOL = require('@/assets/icons/spp_symbol.png')
export const ICON_START_CHECK = require('@/assets/icons/start_check.png')
export const ICON_START_REPORT = require('@/assets/icons/start_report.png')
export const ICON_TRASH = require('@/assets/icons/trash.png')
export const ICON_UNDO = require('@/assets/icons/undo.png')
export const ICON_VOES_LOGO_ORIGINAL = require('@/assets/icons/voes_logo_original.png')
export const ICON_EU_BANNER = require('@/assets/icons/eu_banner.jpg')
