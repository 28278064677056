import QuestionnaireCategory from "../types/QuestionnaireCategory";
import QuestionnaireTopic from "../types/QuestionnaireTopic";
import Question from "@/api/types/Question";
import {extractArrayValues} from "@/api/lib";

export default class Questionnaire {
    public id: number
    public name: string
    public description: string|null
    public state: number
    public categories: QuestionnaireCategory[]
    public topics: QuestionnaireTopic[]
    public questions: Question[]

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.name = data.name
        this.description = data.description || null
        this.state = parseInt(data.state)

        this.categories = extractArrayValues(
            data,
            'categories.category',
            (c: any) => new QuestionnaireCategory(c)
        )

        this.topics = extractArrayValues(
            data,
            'topics.topic',
            (c: any) => new QuestionnaireTopic(c)
        )

        this.questions = extractArrayValues(
            data,
            'questions.question',
            (c: any) => new Question(c)
        )
    }
}