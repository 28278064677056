import AbstractEntity from "./AbstractEntity";
import QuestionnaireCategory from "@/api/types/QuestionnaireCategory";

const TABLE_NAME = 'QuestionnaireCategory'

export default class QuestionnaireCategoryEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionnaireId',
        ])
    }

    public static async persist(db: IDBDatabase, category: QuestionnaireCategory) {
        const data = super.getPersistData<QuestionnaireCategory>(category, [
            'id',
            'name',
            'description',
            'questionnaireId',
            'color',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async getById(db: IDBDatabase, id: number) {
        return this._findOne<QuestionnaireCategory>(
            db,
            TABLE_NAME,
            id,
        );
    }

    static async findAllByQuestionnaire(db: IDBDatabase, questionnaireId: number): Promise<QuestionnaireCategory[]> {
        return this._find<QuestionnaireCategory>(
            db,
            TABLE_NAME,
            questionnaireId,
            'questionnaireId',
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}