<template>
  <div class="location-selection">
    <div class="header">Betriebsauswahl</div>
    <select v-model="locationId">
      <option v-for="location of user.locations" :value="location.id" :key="location.id">{{ `${location.name} - ${location.registrationNr}` }}</option>
    </select>
  </div>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import {useStore} from "vuex";
import User from "@/api/types/User";

const store = useStore()

const locationId = ref<number|null>(store.state.selectedLocationId)
const user = ref<User>(store.state.configuration.user)

watch(locationId, () => store.commit('SET_LOCATION_ID', locationId.value))
</script>

<style lang="scss">
.location-selection {
  border-bottom: 1px solid #000;
  margin: 0 5px 20px;

  .header {
    width: 100%;
    background: #cbcbcb;
    text-align: center;
    padding: 0;
    font-size: 13pt;
  }

  select {
    width: 100%;
    border: none;
    background: none;
    padding: 12px 0;
    font-size: 15pt;

    &, &:focus-visible {
      border: none;
      outline: none;
    }
  }
}
</style>