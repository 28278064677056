export default class ChangePasswordRequest {
    private username: string;
    private oldPassword: string;
    private newPassword: string;

    constructor(username: string, oldPassword: string, newPassword: string) {
        this.username = username;
        this.oldPassword = oldPassword;
        this.newPassword = newPassword;
    }
}