<template>
  <div class="questionnaire-list-container">
    <ul class="questionnaire-list">
      <li v-for="questionnaire of data" :key="questionnaire.id" :class="{ expanded: expandedQuestionnaireId === questionnaire.id }">
        <div class="row-level1">
          <a href="javascript: void(0)" @click="() => toggleExpandedQuestionnaire(questionnaire.release.id, questionnaire.id)" class="icon toggle">
            <img :src="expandedQuestionnaireId === questionnaire.id ? ICON_ARROW_DOWN : ICON_ARROW_RIGHT">
          </a>
          <a href="javascript: void(0)" @click="() => toggleExpandedQuestionnaire(questionnaire.release.id, questionnaire.id)" class="name">
            {{ questionnaire.name }}
          </a>
          <a href="javascript: void(0)" @click="() => promptNewItem(questionnaire.release.id, questionnaire.id)" class="icon add" v-if="mode === 'check'">
            <img :src="ICON_ADD">
          </a>
        </div>

        <ul>
          <li v-for="userQuestionnaire of questionnaire.userQuestionnaires" :key="userQuestionnaire.id">
            <div class="row-level2">
              <div class="radio">
                <input type="radio"
                       :name="`radio-${questionnaire.id}`"
                       :id="`radio-${questionnaire.id}-${userQuestionnaire.pk}`"
                       @change="(e) => onRadioChange(e, userQuestionnaire.pk)"
                       :checked="selectedUserQuestionnairePk === userQuestionnaire.pk">
              </div>
              <label :for="`radio-${questionnaire.id}-${userQuestionnaire.pk}`">
                {{ userQuestionnaire.description }}
              </label>
              <div class="progress-container">
                {{ userQuestionnaire.progress }}%
              </div>
              <div class="icon trash" @click="() => removeItem(userQuestionnaire.pk)" v-if="mode === 'check'">
                <img :src="ICON_TRASH">
              </div>
            </div>
          </li>
        </ul>
      </li>
    </ul>
    <app-alert
        :show="promptForNewItem"
        show-cancel
        button-label="SPEICHERN"
        @ok="addNewItem"
        @cancel="cancelNewItem"
    >
      <form class="form" @submit.prevent="addNewItem">
        <label>
          Bezeichnung
          <input type="text" v-model="newItemName" autofocus>
        </label>
        <small>z.B. Mast 2023</small>
      </form>
    </app-alert>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue";
import QuestionnaireReleaseEntity from "@/db/QuestionnaireReleaseEntity";
import getDb from "@/db/Database";
import QuestionnaireEntity from "@/db/QuestionnaireEntity";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import Questionnaire from "@/api/types/Questionnaire";
import {ICON_ADD, ICON_ARROW_DOWN, ICON_ARROW_RIGHT, ICON_TRASH} from "@/lib/icons";
import AppAlert from "@/components/AppAlert.vue";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import emitter from "@/lib/eventBus";
import {useStore} from "vuex";
import {useRouter} from "vue-router";

type DataType = Questionnaire & {
  release: QuestionnaireRelease
  userQuestionnaires: UserQuestionnaire[]
}

const props = defineProps<{
  mode: string,
  releaseId: number|null
  questionnaireId: number|null
  userQuestionnaireId: number|null
}>()

const emit = defineEmits([
  'update:releaseId',
  'update:questionnaireId',
  'update:userQuestionnaireId'
])

const store = useStore()
const router = useRouter()

const data = ref<DataType[]>([])

const expandedQuestionnaireId = ref<number|null>(null)
const selectedReleaseId = ref<number|null>(null)
const selectedUserQuestionnairePk = ref<number|null>(null)

const selectedLocationId = computed<number>(() => store.state.selectedLocationId)

const promptForNewItem = ref<boolean>(false)
const newItemName = ref<string>('')

const toggleExpandedQuestionnaire = (releaseId: number, questionnaireId: number) => {
  selectedUserQuestionnairePk.value = null

  if (questionnaireId === expandedQuestionnaireId.value) {
    selectedReleaseId.value = null
    expandedQuestionnaireId.value = null
  } else {
    selectedReleaseId.value = releaseId
    expandedQuestionnaireId.value = questionnaireId
  }
}

const promptNewItem = (releaseId: number, questionnaireId: number) => {
  selectedReleaseId.value = releaseId
  expandedQuestionnaireId.value = questionnaireId
  selectedUserQuestionnairePk.value = null
  promptForNewItem.value = true
}

const cancelNewItem = () => {
  promptForNewItem.value = false
  newItemName.value = ''
}

const addNewItem = async () => {
  const newUserQuestionnaire = new UserQuestionnaire({
    progress: 0,
    state: 1,
    description: newItemName.value,
    releaseId: selectedReleaseId.value,
    locationId: store.state.selectedLocationId,
    dirty: true
  } as UserQuestionnaire)

  const newItem = await UserQuestionnaireEntity.persist(getDb(), newUserQuestionnaire)

  promptForNewItem.value = false
  newItemName.value = ''

  if (newItem && selectedReleaseId.value) {
    await load()

    // @ts-ignore
    const questionnaire = await UserQuestionnaireEntity.findByPk(getDb(), newItem.pk)
    const release = await QuestionnaireReleaseEntity.find(getDb(), selectedReleaseId.value)
    store.commit('SET_QUESTIONNAIRE', { questionnaire, release })
    await router.push({ name: 'categoryFilter' });
  }
}

const removeItem = async (pk: number|null) => {
  if (pk) {
    await UserQuestionnaireEntity.updateByPk(getDb(), pk, {
      dirty: 1,
      deleted: 1,
    })

    if (pk === selectedUserQuestionnairePk.value) {
      selectedUserQuestionnairePk.value = null
    }

    await load()
  }
}

const onRadioChange = (e: any, pk: number|null) => {
  if (e.target.checked) {
    selectedUserQuestionnairePk.value = pk
  }
}

const load = async () => {
  console.log('load', selectedLocationId.value)
  const releases = await QuestionnaireReleaseEntity.getAll(getDb())

  const res = []
  for (const release of releases) {
    const questionnaire = <DataType>await QuestionnaireEntity.find(getDb(), release.questionnaireId)

    if (questionnaire) {
      const userQuestionnaires = props.mode === 'check'
        ? await UserQuestionnaireEntity.getForCheckByRelease(getDb(), release.id, store.state.selectedLocationId)
        : await UserQuestionnaireEntity.getForReportByRelease(getDb(), release.id, store.state.selectedLocationId)

      userQuestionnaires?.sort((a, b) => a.description > b.description ? 1 : -1)

      questionnaire.release = release
      questionnaire.userQuestionnaires = <UserQuestionnaire[]>userQuestionnaires

      res.push(questionnaire)
    }
  }

  data.value = res
}

emitter.on('RELEASES_LOADED', load)

watch(selectedReleaseId, () => emit('update:releaseId', selectedReleaseId.value))
watch(expandedQuestionnaireId, () => emit('update:questionnaireId', expandedQuestionnaireId.value))
watch(selectedUserQuestionnairePk, () => emit('update:userQuestionnaireId', selectedUserQuestionnairePk.value))
watch(selectedLocationId, load)

load()

</script>
