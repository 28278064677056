<template>
  <app-activity title="Risiko - Analyse" id="category-filter-activity" :icons="icons" @icon-click="onIconClick">
    <header>Welche Teile des Fragebogens wollen Sie beantworten?</header>

    <ul class="questionnaire-list">
      <li v-for="category of allCategories" :key="category.id">
        <div class="row-level2">
          <div class="checkbox">
            <input type="checkbox" :id="category.id" v-model="checkStatus[category.id]">
          </div>
          <label :for="category.id" class="name">{{ category.name }}</label>
          <div class="progress-container">{{ category.userCategory?.progress || 0 }}%</div>
        </div>
      </li>
    </ul>
  </app-activity>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import AppActivity from "@/components/AppActivity.vue";

import {ICON_CANCEL, ICON_OK} from "@/lib/icons";
import {useRoute, useRouter} from "vue-router";
import QuestionnaireEntity from "@/db/QuestionnaireEntity";
import getDb from "@/db/Database";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import QuestionnaireCategoryEntity from "@/db/QuestionnaireCategoryEntity";
import {ref} from "vue";
import QuestionnaireCategory from "@/api/types/QuestionnaireCategory";
import {hideSpinner, showSpinner} from "@/store";
import UserQuestionnaireCategoryEntity from "@/db/UserQuestionnaireCategoryEntity";
import UserQuestionnaireCategory from "@/api/types/UserQuestionnaireCategory";

type CategoryEntry = QuestionnaireCategory & {
  userCategory: UserQuestionnaireCategory
}

const icons = [
  ICON_OK,
  ICON_CANCEL
]

const store = useStore()
const router = useRouter()
const route = useRoute()

const mode: string = <string>route.params.mode

const allCategories = ref<CategoryEntry[]>([])

const userQuestionnaire: UserQuestionnaire = store.state.currentQuestionnaire
const release: QuestionnaireRelease = store.state.currentRelease

const checkStatus = ref<{ [id: number]: boolean }>({})

const onIconClick = async (icon: string) => {
  switch (icon) {
    case ICON_OK:
      const selectedCategories = Object.keys(checkStatus.value)
          .filter((id: any) => !!checkStatus.value[id])
          .map((id: any): number => parseInt(id))

      store.commit('check/SET_CATEGORIES', selectedCategories)
      await router.push({ name: 'check' })
      break
    case ICON_CANCEL:
      store.commit('SET_QUESTIONNAIRE', { questionnaire: null, release: null })
      await router.push({ name: 'questionnaire' })
      break
  }
}

const load = async () => {
  showSpinner()
  const questionnaire = await QuestionnaireEntity.find(getDb(), release.questionnaireId)
  if (questionnaire) {
    const categories = await QuestionnaireCategoryEntity.findAllByQuestionnaire(getDb(), questionnaire.id)
    if (categories) {
      categories.sort((a, b) => <string>a.name > <string>b.name ? 1 : -1)

      // @ts-ignore
      allCategories.value = await Promise.all(categories.map(async (category) => {
        const userCategory = await UserQuestionnaireCategoryEntity.findByCategoryId(getDb(), <number>userQuestionnaire.pk, category.id)
        if (typeof checkStatus.value[category.id] === 'undefined') {
          checkStatus.value[category.id] = !userCategory || userCategory.progress < 100
        }
        return {
          ...category,
          userCategory,
        }
      }))
    }
  }
  hideSpinner()
}

if (!userQuestionnaire || !release) {
  router.push({ name: 'questionnaire' })
} else {
  load()
}
</script>

<style lang="scss">
#category-filter-activity {
  header {
    background: var(--questionnaire-header-color);
    font-size: var(--content-header-font-size);
    font-weight: 500;
    text-align: center;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-top: -5px;
    margin-bottom: 15px;
  }
}
</style>