<template>
  <app-activity title="Risiko - Analyse" id="category-filter-activity" :icons="icons" @icon-click="onIconClick">
    <header>Schwerpunkt(e) auswählen</header>

    <ul class="questionnaire-list">
      <li v-for="topic of allTopics" :key="topic.id">
        <div class="row-level2">
          <div class="checkbox">
            <input type="checkbox" :id="topic.id" v-model="checkStatus[topic.id]" checked>
          </div>
          <label :for="topic.id" class="name">{{ topic.name }}</label>
          <div class="progress-container">{{ topic.userTopic?.progress || 0 }}%</div>
        </div>
      </li>
    </ul>
  </app-activity>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import AppActivity from "@/components/AppActivity.vue";

import {ICON_CANCEL, ICON_OK, ICON_REPORT_ICON} from "@/lib/icons";
import {useRoute, useRouter} from "vue-router";
import QuestionnaireEntity from "@/db/QuestionnaireEntity";
import getDb from "@/db/Database";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import QuestionnaireCategoryEntity from "@/db/QuestionnaireCategoryEntity";
import {ref} from "vue";
import QuestionnaireCategory from "@/api/types/QuestionnaireCategory";
import {hideSpinner, showAlert, showSpinner} from "@/store";
import UserQuestionnaireCategoryEntity from "@/db/UserQuestionnaireCategoryEntity";
import UserQuestionnaireCategory from "@/api/types/UserQuestionnaireCategory";
import QuestionnaireTopicEntity from "@/db/QuestionnaireTopicEntity";
import UserQuestionnaireTopicEntity from "@/db/UserQuestionnaireTopicEntity";
import UserQuestionnaireTopic from "@/api/types/UserQuestionnaireTopic";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import ReportRequest from "@/api/requests/ReportRequest";
import GetReportResponse from "@/api/responses/GetReportResponse";
import {useApiClient} from "@/api";

type TopicEntry = QuestionnaireTopic & {
  userTopic: UserQuestionnaireTopic
}

const icons = [
  ICON_REPORT_ICON,
  ICON_CANCEL
]

const store = useStore()
const router = useRouter()
const route = useRoute()
const api = useApiClient()

const mode: string = <string>route.params.mode

const allTopics = ref<TopicEntry[]>([])

const userQuestionnaire: UserQuestionnaire = store.state.currentQuestionnaire
const release: QuestionnaireRelease = store.state.currentRelease

const checkStatus = ref<{ [id: number]: boolean }>({})

const onIconClick = async (icon: string) => {
  switch (icon) {
    case ICON_REPORT_ICON:
      const selectedTopics: QuestionnaireTopic[] = Object.keys(checkStatus.value)
          .filter((id: any) => !!checkStatus.value[id])
          .map((id: any) => {
            id = parseInt(id)
            const searchRes = allTopics.value.filter(topic => topic.id === id)
            if (!searchRes) {
              showAlert('Ein interner Fehler ist aufgetreten')
              throw new Error(`topic with id ${id} not found`)
            }
            return searchRes[0]
          })

        store.commit('SET_SELECTED_TOPICS', selectedTopics)

        if (mode !== 'optimize') {
          await router.push({ name: 'comparisonChoice' })
        } else {
          await (async () => {
            showSpinner()

            const request = new ReportRequest(2, userQuestionnaire.description, <number>userQuestionnaire.id)
            request.comparison = 0
            request.topics = selectedTopics.map((topic: QuestionnaireTopic) => topic.id)

            api.createReport(store.state.configuration.sessionId, request)
                .then(async (res: GetReportResponse) => {
                  try {
                    await res.openFile()
                  } catch (error: any) {
                    showAlert('Fehler: ' + error.message)
                  }
                  hideSpinner()
                })
                .catch(err => {
                  console.error('ERROR', err)
                  hideSpinner()
                })
          })()
        }
      break
    case ICON_CANCEL:
      store.commit('SET_QUESTIONNAIRE', { questionnaire: null, release: null })
      await router.push({ name: 'questionnaire' })
      break
  }
}

const load = async () => {
  showSpinner()
  const questionnaire = await QuestionnaireEntity.find(getDb(), release.questionnaireId)
  if (questionnaire) {
    const topics = await QuestionnaireTopicEntity.findAllByQuestionnaire(getDb(), questionnaire.id)
    if (topics) {
      topics.sort((a, b) => <string>a.name > <string>b.name ? 1 : -1)

      // @ts-ignore
      allTopics.value = await Promise.all(topics.map(async (topic) => {
        if (typeof checkStatus.value[topic.id] === 'undefined') {
          checkStatus.value[topic.id] = mode !== 'optimize'
        }
        return {
          ...topic,
          userTopic: await UserQuestionnaireTopicEntity.findByTopicId(getDb(), <number>userQuestionnaire.pk, topic.id)
        }
      }))
    }
  }
  hideSpinner()
}

if (!userQuestionnaire || !release) {
  router.push({ name: 'questionnaire' })
} else {
  load()
}
</script>

<style lang="scss">
#category-filter-activity {
  header {
    background: var(--questionnaire-header-color);
    font-size: var(--content-header-font-size);
    font-weight: 500;
    text-align: center;
    margin-left: -15px;
    width: calc(100% + 30px);
    margin-top: -5px;
    margin-bottom: 15px;
  }
}
</style>