import AbstractEntity from "./AbstractEntity";
import QuestionDependency from "@/api/types/QuestionDependency";
import DependencyQuestionAnswerEntity from "@/db/DependencyQuestionAnswerEntity";

const TABLE_NAME = 'QuestionDependency'

export default class QuestionDependencyEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionId',
            'dependencyQuestionId',
        ])
    }

    public static async persist(db: IDBDatabase, questionDependency: QuestionDependency) {
        const data = super.getPersistData<QuestionDependency>(questionDependency, [
            'id',
            'questionId',
            'dependencyQuestionId',
        ])

        await super._put(db, TABLE_NAME, data)

        for (const answer of questionDependency.answers) {
            await DependencyQuestionAnswerEntity.persist(db, answer)
        }
    }

    static findForQuestion(db: IDBDatabase, questionId: number) {
        return this._find<QuestionDependency>(
            db,
            TABLE_NAME,
            questionId,
            'questionId',
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}