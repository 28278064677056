<template>
  <div class="questionnaire-list">
    <div class="row-level2" v-for="answer of question.answers" :key="answer.id">
      <div class="checkbox">
        <input type="checkbox" :id="`answer-${answer.id}`" :name="`choice-${question.id}`" :value="answer.id" v-model="value[answer.id]">
      </div>
      <label :for="`answer-${answer.id}`">{{ answer.description }}</label>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {computed, ref, watch} from "vue";
import {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";

const props = defineProps<{
  modelValue: RawAnswer
  question: QuestionEntry
}>()

const emit = defineEmits(["update:modelValue"])

const value = ref<{ [id: number]: boolean}>({})

const emitAnswer = () => {
  const enabledKeys = Object.keys(value.value)
      .filter((key: any) => value.value[key])

  emit(
      "update:modelValue",
      enabledKeys
  )
}

watch(() => props.question, () => {
  value.value = props.question.answers.reduce((res: { [id: number]: boolean}, answer) => {
    res[answer.id] = !! props.question.selectedAnswers.filter(selectedAnswer => selectedAnswer.id === answer.id).length
    return res
  }, {})
}, { immediate: true, deep: true })

watch(() => value, emitAnswer, { immediate: true, deep: true })
</script>
