import QuestionDependency from "@/api/types/QuestionDependency";
import AnswerDependency from "@/api/types/AnswerDependency";
import Answer from "@/api/types/Answer";
import AddOn from "@/api/types/AddOn";
import {extractArrayValues} from "@/api/lib";
import AbstractType from "@/api/types/AbstractType";
import AnswerEntity from "@/db/AnswerEntity";

export default class Question extends AbstractType {
    public id: number
    public questionnaireId: number
    public topicId: number|null
    public categoryId: number|null
    public number: number|null
    public subNumber: number|null
    public numberString: string|null
    public question: string|null
    public mandatory: boolean
    public information: string|null
    public parentQuestionId: number|null
    public type: number|null
    public plausibility: string|null
    public farmType: number|null
    public addOns: AddOn[]
    public answers: Answer[]
    public dependencies: AnswerDependency[]
    public questionDependencies: QuestionDependency[]

    constructor(data: any) {
        super()
        this.id = parseInt(data.id)
        this.questionnaireId = parseInt(data.questionnaireId)
        this.topicId = data.topicId ? parseInt(data.topicId) : null
        this.categoryId = data.categoryId ? parseInt(data.categoryId) : null
        this.number = data.number ? parseInt(data.number) : null
        this.subNumber = data.subNumber ? parseInt(data.subNumber) : null
        this.numberString = data.numberString || null
        this.question = data.question || null
        this.mandatory = data.mandatory === 'true'
        this.information = data.information || null
        this.parentQuestionId = data.parentQuestionId ? parseInt(data.parentQuestionId) : null
        this.type = data.type ? parseInt(data.type) : null
        this.plausibility = data.plausibility || null
        this.farmType = data.farmType ? parseInt(data.farmType) : null

        this.addOns = extractArrayValues(
            data,
            'addOns.addOn',
            (a: any) => new AddOn(a))

        this.answers = extractArrayValues(
            data,
            'answers.answer',
            (a: any) => new Answer(a))

        this.dependencies = extractArrayValues(
            data,
            'dependencies.dependency',
            (d: any) => new AnswerDependency(d))

        this.questionDependencies = extractArrayValues(
            data,
            'questionDependencies.questionDependency',
            (d: any) => new QuestionDependency(d))
    }
}