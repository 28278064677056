import {Module} from "vuex";
import store from "@/store";

type State = {
    isOffline: boolean
    promptUpload: boolean
}

const offline: Module<State, any> = {
    namespaced: true,

    state: {
        isOffline: !navigator.onLine,
        promptUpload: false,
    },

    mutations: {
        SET_STATE: (state: State, online: boolean) => {
            state.isOffline = !online
        },
        PROMPT_UPLOAD: (state: State, show: boolean = true) => {
            state.promptUpload = show
        },
    },

    actions: {
        HANDLE: (context, online: boolean) => {
            context.commit('SET_STATE', online)

            if (online) {
                context.commit('SHOW_SNACKBAR', { message: 'Sie sind online', duration: 1000 }, { root: true })
            } else {
                context.commit('SHOW_SNACKBAR', { message: 'Sie sind offline', duration: 1000 }, { root: true })
            }
        }
    },
}

window.addEventListener('online', async () => {
    await store.dispatch('offline/HANDLE', true)
})
window.addEventListener('offline', async () => {
    await store.dispatch('offline/HANDLE', false)
})

export default offline