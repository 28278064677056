import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import AbstractEntity from "./AbstractEntity";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";

const TABLE_NAME = 'QuestionnaireRelease'

export default class QuestionnaireReleaseEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id'
        ])
    }

    public static async persist(db: IDBDatabase, release: QuestionnaireRelease) {
        const data = super.getPersistData<QuestionnaireRelease>(release, [
            'id',
            'duration',
            'durationUnit',
            'fromDate',
            'interval',
            'intervalUnit',
            'mandatory',
            'questionnaireId',
            'releaseToDate',
            'reminderDays',
            'state',
            'thresholdAnalysis',
            'toDate',
            'type',
        ])

        await super._put(db, TABLE_NAME, data)

        release.questionnaires.map(questionnaire => {
            UserQuestionnaireEntity.persist(db, questionnaire)
        })
    }

    static async find(db: IDBDatabase, id: number): Promise<QuestionnaireRelease|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            id,
        )
    }

    static async getAll(db: IDBDatabase): Promise<QuestionnaireRelease[]> {
        return super._getAll<QuestionnaireRelease>(db, TABLE_NAME)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}