import {strToBoolNum} from "@/api/lib";

export default class Membership {
    public id: number
    public code: number
    public name: string|null
    public numberMandatory: number|null

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.code = parseInt(data.code)
        this.name = data.name || null
        this.numberMandatory = strToBoolNum(data.numberMandatory)
    }
}