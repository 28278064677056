import {createStore} from 'vuex'
import {useApiClient} from "@/api";
import Configuration from "@/api/types/Configuration";
import ConfigurationEntity from "@/db/ConfigurationEntity";
import getDb, {clearDb, initDb} from "@/db/Database";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import QuestionnaireRelease from "@/api/types/QuestionnaireRelease";
import QuestionnaireTopic from "@/api/types/QuestionnaireTopic";
import emitter from "@/lib/eventBus";
import offline from "@/store/module/offline";
import check from "@/store/module/check";
import Location from "@/api/types/Location";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import User from "@/api/types/User";

export type AppState = {
  configuration: Configuration|null
  initialized: boolean
  showSpinner: boolean
  alert: {
    show: boolean
    title: string|null
    message: string|null
    button: string
    showCancel: boolean
    cancelButtonLabel: string
    lastResult: boolean|null
    smallFont: boolean
  },
  snackbar: {
    show: boolean
    message: string|null
    timeout: NodeJS.Timeout|null
  },
  currentQuestionnaire: UserQuestionnaire|null
  currentRelease: QuestionnaireRelease|null
  selectedTopics: QuestionnaireTopic[]
  selectedLocationId: number|null
}

const store = createStore<AppState>({
  state: {
    configuration: null,
    initialized: false,
    showSpinner: false,
    alert: {
      show: false,
      title: null,
      message: null,
      button: 'OK',
      showCancel: false,
      cancelButtonLabel: 'ABBRECHEN',
      lastResult: null,
      smallFont: false,
    },
    snackbar: {
      show: false,
      message: null,
      timeout: null,
    },
    currentQuestionnaire: null,
    currentRelease: null,
    selectedTopics: [],
    selectedLocationId: null,
  },
  getters: {
    selectedLocation(state): Location|null {
      if (!state.selectedLocationId || !state.configuration) {
        return null
      }
      return state.configuration?.user.locations.find(loc => loc.id === state.selectedLocationId) || null
    }
  },
  mutations: {
    SET_INITIALIZED: (state) => {
      state.initialized = true
    },
    SET_CONFIGURATION: (state, configuration: Configuration) => {
      state.configuration = configuration
      ConfigurationEntity.persist(getDb(), configuration)

      if (!state.selectedLocationId && configuration.user) {
        state.selectedLocationId = configuration.user.locations[0].id
      }
    },
    UPDATE_USER: (state, user: User) => {
      if (state.configuration) {
        const configuration = { ...state.configuration }
        configuration.user = JSON.parse(JSON.stringify(user))
        state.configuration = configuration
        ConfigurationEntity.persist(getDb(), configuration)

        // @ts-ignore
        if (state.selectedLocationId && user.locations.find(state.selectedLocationId) < 0) {
          state.selectedLocationId = null
        }

        if (!state.selectedLocationId && configuration.user) {
          state.selectedLocationId = configuration.user.locations[0].id
        }
      }
    },
    SET_QUESTIONNAIRE: (state, { questionnaire, release }: { questionnaire: UserQuestionnaire, release: QuestionnaireRelease }) => {
      state.currentQuestionnaire = questionnaire
      state.currentRelease = release
    },
    SET_SELECTED_TOPICS: (state, topics: QuestionnaireTopic[]) => {
      state.selectedTopics = topics
    },
    CLEAR_CONFIGURATION: async (state) => {
      state.configuration = null
      await ConfigurationEntity.clear(getDb())
    },
    SET_LAST_REFRESH: async (state, date: Date) => {
      if (!state.configuration) {
        throw new Error('no configuration set')
      }
      const configuration = await ConfigurationEntity.get(getDb())
      if (configuration) {
        configuration.lastRefresh = date
        await ConfigurationEntity.persist(getDb(), configuration)
      }
    },
    SET_LOCATION_ID: (state, locationId: number) => {
      state.selectedLocationId = locationId
    },
    SHOW_ALERT: (state, { title, message, button = 'OK', showCancel = false, cancelButtonLabel = 'ABBRECHEN', smallFont = false }) => {
      state.alert = { show: true, title, message, button, showCancel, cancelButtonLabel, lastResult: null, smallFont }
    },
    HIDE_ALERT: (state, result: boolean) => {
      state.alert.show = false
      state.alert.lastResult = result
    },
    SHOW_SPINNER: (state) => {
      state.showSpinner = true
    },
    HIDE_SPINNER: (state) => {
      state.showSpinner = false
    },
    SHOW_SNACKBAR: async (state, { message, duration = 3000 }) => {
      if (state.snackbar.timeout) {
        state.snackbar.show = false
        clearTimeout(state.snackbar.timeout)
        state.snackbar.timeout = null
        await new Promise(resolve => setTimeout(resolve, 200))
      }

      state.snackbar = {
        ...state.snackbar,
        show: true,
        message,
      }

      state.snackbar.timeout = setTimeout(() => {
        state.snackbar.show = false
        // @ts-ignore
        clearTimeout(state.snackbar.timeout)
        state.snackbar.timeout = null
      }, duration)
    },
  },
  actions: {
    INITIALIZE: async (context) => {
      const event = window.isCordova()
          ? { target: document, event: 'deviceready' }
          : { target: window, event: 'load' }

      await new Promise((resolve) => {
        event.target.addEventListener(event.event, async () => {
          try {
            await initDb(3)
            console.debug('initialized database')

            const configuration = await ConfigurationEntity.get(getDb())
            if (configuration) {
              const apiClient = useApiClient()

              try {
                const response = await apiClient.login(configuration.username, configuration.password)
                if (response.sessionId) {
                  configuration.sessionId = response.sessionId
                }
                resolve(true)
              } catch (err: any) {
                console.error(err)
                resolve(false)
              }

              context.commit('SET_CONFIGURATION', configuration)
            }

          } catch (err: any) {
            showAlert(err.message)
          }

          resolve(true)
        })
      })

      emitter.on('API_SESSION_REFRESH', async (sessionId: string) => {
        const configuration = await ConfigurationEntity.get(getDb());
        if (configuration) {
          configuration.sessionId = sessionId
          context.commit('SET_CONFIGURATION', configuration)
        }
      })

      context.commit('SET_INITIALIZED', true)
    },
    SAVE_QUESTIONNAIRE: async (context) => {
      const api = useApiClient()
      const configuration = await ConfigurationEntity.get(getDb())
      if (configuration) {
        const userQuestionnaires = await UserQuestionnaireEntity.getOfflineQuestionnaires(getDb())
        for (const questionnaire of userQuestionnaires) {
          showSpinner()
          try {
            await api.saveUserQuestionnaire(configuration.sessionId, questionnaire)
            hideSpinner()
            await showAlert('Der Fragebogen erfolgreich am Server gespeichert!')
          } catch (err: any) {
            console.error(err)
            hideSpinner()
            await showAlert(err.message)
          }
        }
      }
    },
    RESET_LOCAL_DATA: async (context): Promise<boolean> => {
      context.commit('CLEAR_CONFIGURATION')
      return clearDb()
    }
  },
  modules: {
    offline,
    check,
  }
})

export const showAlert = async (message: string, title: string = 'Fehler', button: string = 'OK', showCancel = false, cancelButtonLabel = 'ABBRECHEN') => {
  store.commit('SHOW_ALERT', { title, message, button, showCancel, cancelButtonLabel })
  return new Promise<void>((resolve) => {
    emitter.once('APP_ALERT_OK', () => resolve())
  })
}

export const showAlertSmall = (message: string, title: string = 'Fehler', button: string = 'OK', showCancel = false, cancelButtonLabel = 'ABBRECHEN') => {
  store.commit('SHOW_ALERT', { title, message, button, showCancel, cancelButtonLabel, smallFont: true })
}

export const showConfirm = (message: string, title: string = 'Fehler', button: string = 'OK', showCancel = true, cancelButtonLabel = 'ABBRECHEN') => {
  store.commit('SHOW_ALERT', { title, message, button, showCancel, cancelButtonLabel })
}

export const showSpinner = () => {
  store.commit('SHOW_SPINNER')
}

export const hideSpinner = () => {
  store.commit('HIDE_SPINNER')
}

export const showSnackbar = (message: string, duration: number = 3000) => {
  store.commit('SHOW_SNACKBAR', { message, duration })
}

export default store
