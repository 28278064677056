<template>
  <app-activity title="Risiko - Analyse" @click="onClick">
    <ul class="home-images">
      <li><img :src="ICON_ARBEITSPLAN_KUPIERVERZICHT"/></li>
      <li><img :src="ICON_BANNER"/></li>
      <li><img :src="ICON_VOES_LOGO_ORIGINAL"/></li>
      <li><img :src="ICON_EU_BANNER"/></li>
    </ul>
  </app-activity>
</template>

<script lang="ts" setup>
import AppActivity from "@/components/AppActivity.vue";

import {ICON_ARBEITSPLAN_KUPIERVERZICHT, ICON_BANNER, ICON_EU_BANNER, ICON_VOES_LOGO_ORIGINAL} from "@/lib/icons";
import {useRouter} from "vue-router";

const router = useRouter()

const onClick = () => {
  router.push({ name: 'menu' })
}
</script>

<style lang="scss">
ul.home-images {
  list-style-type: none;
  margin: 0;
  padding: 0;

  img {
    width: 100%;
  }

  li:not(:last-child) {
    margin-bottom: 60px;
  }
}
</style>
