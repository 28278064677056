import AbstractEntity from "@/db/AbstractEntity";
import UserQuestion from "@/api/types/UserQuestion";
import UserAnswerEntity from "@/db/UserAnswerEntity";
import {boolToNum} from "@/api/lib";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import UserAnswer from "@/api/types/UserAnswer";

const TABLE_NAME = 'UserQuestion'

export default class UserQuestionEntity extends AbstractEntity {

    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'pk', autoIncrement: true }, [
            'pk',
            'id',
            'questionId',
            'userQuestionnaireId',
            'userQuestionnairePk',
        ])
    }

    public static async persist(db: IDBDatabase, question: UserQuestion): Promise<UserQuestion> {
        const data = this.getPersistData<UserQuestion>(question, [
            'id',
            'userQuestionnaireId',
            'userQuestionnairePk',
            'questionId',
            'stringValue',
            'numberValue',
            'dateValue',
            'dirty',
        ])

        data.dirty = boolToNum(data.dirty)

        if (question.pk && await this.findByPk(db, question.pk)) {
            data.pk = question.pk
            await this.updateByPk(db, data.pk, data)
        }
        else if (data.id && await this.findById(db, data.id)) {
            // @ts-ignore
            const newItem: UserQuestion|null = await this.updateById(db, data.id, data)
            if (newItem) {
                data.pk = newItem.pk
            }
        }
        else {
            data.pk = await super._put(db, TABLE_NAME, data)
        }

        if (question.answers) {
            const newAnswers: UserAnswer[] = []
            for (let answer of question.answers) {
                answer.userQuestionPk = data.pk
                if (data.dirty) {
                    answer.dirty = 1
                }
                newAnswers.push({
                    ...answer,
                    ...(await UserAnswerEntity.persist(db, answer))
                })
            }
            data.answers = newAnswers
        }

        return data
    }

    static async get(db: IDBDatabase, userQuestionnairePk: number, questionId: number) {
        return this._findOne<UserQuestion>(
            db,
            TABLE_NAME,
            questionId,
            'questionId',
            (q: UserQuestion) => q.userQuestionnairePk === userQuestionnairePk
        )
    }

    static async findByPk(db: IDBDatabase, pk: number): Promise<UserQuestion|null> {
        return super._findOne(
            db,
            TABLE_NAME,
            pk,
            'pk'
        )
    }

    static async findById(db: IDBDatabase, id: number): Promise<UserQuestion|null> {
        return super._findOne<UserQuestion>(
            db,
            TABLE_NAME,
            id,
            'id'
        )
    }

    static async findByQuestionnairePk(db: IDBDatabase, questionnairePk: number) {
        return super._find<UserQuestion>(
            db,
            TABLE_NAME,
            questionnairePk,
            'userQuestionnairePk'
        )
    }

    static async updateByPk(db: IDBDatabase, pk: number, data: object) {
        return super._update<UserQuestion>(
            db,
            TABLE_NAME,
            pk,
            'pk',
            data)
    }

    static async updateById(db: IDBDatabase, id: number, data: object) {
        return super._update<UserQuestion>(
            db,
            TABLE_NAME,
            id,
            'id',
            data)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}
