<template>
  <app-activity title="Risiko - Analyse" :icons="icons" @icon-click="onIconClick">
    <form class="form profile-form" :class="{ validated: formGotValidated }" @submit.prevent="onSubmit" ref="form">
      <label>
        Nachname
        <input type="text" v-model="data.lastname" :disabled="disabled" required/>
      </label>
      <label>
        Vorname
        <input type="text" v-model="data.firstname" :disabled="disabled" required/>
      </label>
      <label>
        Adresse
        <input type="text" v-model="data.address" :disabled="disabled" required/>
      </label>
      <label>
        PLZ/Ort
        <span class="input-group input-group-1-2">
          <input type="text" v-model="data.zip" :disabled="disabled" required/>
          <input type="text" v-model="data.city" :disabled="disabled" required/>
        </span>
      </label>
      <label>
        E-Mail Adresse
        <input type="email" v-model="data.email" :disabled="disabled" required/>
      </label>
      <label>
        Mitgliedschaft
        <select v-model="data.membershipId" required>
          <option value="">-- bitte auswählen --</option>
          <option :value="membership.id" v-for="membership of memberships" :key="membership.id">
            {{ membership.name }}
          </option>
        </select>
      </label>
      <label>
        Mitgliedsnummer
        <input type="number" v-model="data.membershipNumber" :disabled="disabled" :required="membershipNrMandatory"/>
      </label>
      <label>
        Benutzername
        <input type="text" v-model="data.username" :disabled="disabled" required/>
      </label>

      <label>Standorte</label>

      <ul class="locations" v-if="data.locations && data.locations.length">
        <li v-for="(location, index) of data.locations" :key="index">
          <div class="number">{{ location.registrationNr }}</div>
          <div class="name">{{ location.name }}</div>
          <div class="delete">
            <a href="javascript:void(0);" @click="() => removeLocation(`${location.registrationNr}||${location.name}`)">
              <img :src="ICON_TRASH">
            </a>
          </div>
        </li>
      </ul>

      <label class="new-location">
        <div class="input-group">
          <input type="number" v-model="newLocation.registrationNr" :disabled="disabled" placeholder="LFBIS-Nummer" />
          <input type="text" v-model="newLocation.name" :disabled="disabled" placeholder="Standortbezeichnung" />
          <a href="javascript:void(0);" @click="() => addLocation()" :class="{ disabled: !newLocationValid }">
              <img :src="ICON_ADD">
            </a>
        </div>
      </label>

      <div v-if="!configuration">
        <gdpr-checkbox v-model="gdprChecked"/>
        <gdpr-text/>
      </div>
    </form>
  </app-activity>
</template>

<script lang="ts" setup>
import AppActivity from "@/components/AppActivity.vue";
import {useStore} from "vuex";
import {ICON_ADD, ICON_CANCEL, ICON_OK, ICON_TRASH} from "@/lib/icons";
import {useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import {User} from "@/api/types";
import GdprCheckbox from "@/components/login/GdprCheckbox.vue";
import GdprText from "@/components/login/GdprText.vue";
import getDb from "@/db/Database";
import MembershipEntity from "@/db/Membersip";
import {hideSpinner, showAlert, showSpinner} from "@/store";
import {useApiClient} from "@/api";
import Membership from "@/api/types/Membership";
import Location from "@/api/types/Location";

const store = useStore()
const router = useRouter()
const db = getDb()

const icons = [
    ICON_OK,
    ICON_CANCEL,
]

const memberships = ref<Membership[]>([])
const disabled = ref<boolean>(false)

// @ts-ignore
const data = ref<User>({
  locations: []
})
const gdprChecked = ref<boolean>(false)
const formGotValidated = ref<boolean>(false)
const membershipNrMandatory = ref<boolean>(true)

const isRegistration = ref<boolean>(true)

const newLocation = ref<object>({
  registrationNr: null,
  name: '',
})

const form = ref<HTMLFormElement|null>(null)

const configuration = computed(() => store.state.configuration)

if (configuration.value?.user) {
  // @ts-ignore
  data.value = { ...configuration.value.user }
  isRegistration.value = false
}

// @ts-ignore
if (!data.value.membershipId) {
  // @ts-ignore
  data.value.membershipId = ''
}

if (router.currentRoute.value.name === 'profile' && !configuration.value) {
  router.push({ name: 'login' })
}

if (router.currentRoute.value.name === 'signup' && configuration.value) {
  router.push({ name: 'profile' })
}

const checkMembershipNrMandatory = (id: number) => {
  const membership = memberships.value.find(m => m.id === id)
  if (membership && membership.numberMandatory !== null) {
    membershipNrMandatory.value = membership.numberMandatory > 0
  }
}

const removeLocation = (identifier: string) => {
  const [ number, name ] = identifier.split('||')
  data.value.locations = data.value.locations.filter((l: Location) => l.registrationNr !== parseInt(number) || l.name !== name)
}

const addLocation = () => {
  data.value.locations = [
    ...data.value.locations,
    {...newLocation.value}
  ]
  newLocation.value = {
    registrationNr: null,
    name: ''
  }
}

const newLocationValid = computed<boolean>(() => {
  return newLocation.value.registrationNr
      && newLocation.value.registrationNr > 0
      && newLocation.value.name.length > 0
})

;(async () => {
  showSpinner()
  let membershipEntries = await MembershipEntity.getAll(db)

  if (!membershipEntries.length) {
    if (store.state.offline.isOffline) {
      hideSpinner()
      await showAlert('Sie müssen bei erstmaliger Verwendung dieses Formulars online sein', 'Offline')
      await router.push({ name: 'login' })
    }

    const api = useApiClient()
    const membershipResponse = await api.getMemberships()

    for (const membership of membershipResponse.memberships) {
      await MembershipEntity.persist(db, membership)
      membershipEntries.push(membership)
    }
  }

  memberships.value = membershipEntries
  if (data.value.membershipId) {
    checkMembershipNrMandatory(data.value.membershipId)
  }
  hideSpinner()
})()

watch(() => data.value.membershipId, (id) => {
  if (id) {
    checkMembershipNrMandatory(id)
  }
}, { immediate: true })

const onSubmit = async () => {
  const api = useApiClient()

  showSpinner()
  try {
    if (isRegistration.value) {
      await api.saveRegistration(data.value)
      hideSpinner()
      await showAlert('Die Registrierung war erfolgreich! Sie erhalten in Kürze eine E-Mail mit dem Passwort, mit dem Sie sich anmelden können!')
      await router.push({ name: 'login' })
    } else {
      await api.updateRegistration(store.state.configuration?.sessionId, data.value)
      hideSpinner()
      await showAlert('Ihre Daten wurden erfolgreich aktualisiert')
    }
  } catch (e: any) {
    hideSpinner()
    await showAlert(e.message)
  }
}

const onIconClick = (icon: string) => {
  switch (icon) {
    case ICON_OK:
      const valid = form.value?.checkValidity()
      if (valid) {
        onSubmit()
      } else {
        showAlert('Bitte korrigieren Sie die rot markierten Felder', 'Validierung fehlgeschlagen')
        formGotValidated.value = true
      }
      break
    case ICON_CANCEL:
      router.push({ name: 'login' })
      break
  }
}
</script>

<style lang="scss">
ul.locations {
  border: 1px solid #000;
  list-style-type: none;
  margin: 0;
  padding: 0;

  li {
    display: flex;
    justify-content: space-between;
    padding: 2px 4px;

    .delete img {
      height: 20px;
    }

    &:not(:last-child) {
      border-bottom: 1px solid #efefef;
    }
  }
}
.new-location .input-group {
  display: flex !important;
  flex-direction: row;
  flex-wrap: nowrap;

  a {
    display: block;
    padding-top: 5px;

    img {
      height: 20px;
    }

    &.disabled {
      opacity: .5;
    }
  }
}
</style>