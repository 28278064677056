import AbstractEntity from "./AbstractEntity";
import AddOn from "@/api/types/AddOn";

const TABLE_NAME = 'AddOn'

export default class AddOnEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionId',
        ])
    }

    public static async persist(db: IDBDatabase, addOn: AddOn) {
        const data = super.getPersistData<AddOn>(addOn, [
            'id',
            'questionId',
            'type',
            'value',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async findByQuestion(db: IDBDatabase, questionId: number) {
        return this._find<AddOn>(
            db,
            TABLE_NAME,
            questionId,
            'questionId'
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}