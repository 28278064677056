import ResponseInterface from "@/api/responses/ResponseInterface";
import { Buffer } from 'buffer';

export default class GetReportResponse implements ResponseInterface {
    public data: any|null = null
    public blob: Blob|null = null

    isXmlResponse(): boolean {
        return false
    }

    parse(data: object): void {}

    async setBlob(blob: Blob) {
        this.blob = blob
    }

    async getDataUri(): Promise<string> {
        return new Promise(resolve => {
            if (this.blob) {
                const a = new FileReader();
                a.onload = function(e) {
                    // @ts-ignore
                    resolve(e.target?.result)
                }
                a.onerror = (err) => console.error(err)
                a.readAsDataURL(this.blob);
                // return URL.createObjectURL(this.blob)
            } else {
                resolve('')
            }
        })
    }

    openFile(): Promise<boolean> {
        return new Promise((resolve, reject) => {
            // @ts-ignore
            window.requestFileSystem(window.TEMPORARY, 0, (fs) => {
                console.log('file system open: ' + fs.name);
                // @ts-ignore
                fs.root.getFile("report.pdf", { create: true, exclusive: false }, (fileEntry) => {
                    // @ts-ignore
                    fileEntry.createWriter((fileWriter) => {
                        fileWriter.onwriteend = function() {
                            console.log("Successful file write...", fileEntry);

                            // @ts-ignore
                            if (window.isCordova() && typeof window['requestFileSystem'] !== 'undefined') {
                                // @ts-ignore
                                cordova.plugins.fileOpener2.open(
                                    fileEntry.nativeURL,
                                    'application/pdf',
                                    {
                                        error : function(error: any){
                                            reject(error)
                                        },
                                        success : function(){
                                            resolve(true)
                                        }
                                    }
                                );
                            } else {
                                // @ts-ignore
                                fileEntry.file(function (file) {
                                    const reader = new FileReader();

                                    reader.onloadend = function() {
                                        const link = document.createElement("a");
                                        // @ts-ignore
                                        link.href = this.result
                                        link.download = 'Report.pdf';
                                        link.click();
                                        resolve(true)
                                    };

                                    reader.readAsDataURL(file);

                                }, (err: any) => console.error(err));
                            }
                        };

                        fileWriter.onerror = function (e: any) {
                            console.log("Failed file write: " + e.toString());
                        };

                        // @ts-ignore
                        const dataObj = new Blob([this.blob], { type: 'application/pdf' });

                        fileWriter.write(dataObj);
                    })
                }, (error: any) => {
                    console.error(error)
                    reject(error)
                })
            })
        })
    }
}