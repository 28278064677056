<template>
  <app-activity title="Risiko - Analyse" :icons="icons" @icon-click="onAction">
    <form class="form login-form" @submit.prevent="onSubmit">
      <label>
        Benutzername
        <input type="text" v-model="username" :disabled="store.state.configuration"/>
      </label>
      <label>
        Passwort
        <input type="password" v-model="password" :disabled="store.state.configuration"/>
      </label>

      <ul class="form-links" v-if="!store.state.configuration">
        <li><router-link :to="{ name: 'signup' }">Registrieren &hellip;</router-link></li>
        <li><a href="javascript:void(0)" @click="() => showPasswordForgot = true">Passwort vergessen &hellip;</a></li>
      </ul>

      <ul class="form-links" v-else>
        <li><a href="javascript:void(0)" @click="() => showPasswordChange = true">Passwort ändern &hellip;</a></li>
        <li><router-link :to="{ name: 'profile' }">Profil &hellip;</router-link></li>
        <li><a href="javascript:void(0)" @click="() => onAction(<string>ICON_UNDO)">Abmelden &hellip;</a></li>
        <li><a href="javascript:void(0)" @click="() => showProfileDeletion = true">Zugang löschen</a></li>
      </ul>

      <gdpr-text/>
    </form>

    <login-password-change-modal :show="showPasswordChange" @ok="onPasswordChange" @cancel="() => showPasswordChange = false"/>
    <login-password-forgot-modal :show="showPasswordForgot" @ok="onPasswordForgot" @cancel="() => showPasswordForgot = false"/>
    <profile-deletion-modal :show="showProfileDeletion" @ok="onProfileDeletion" @cancel="() => showProfileDeletion = false"/>

    <gdpr-checkbox v-model="gdprChecked"/>
  </app-activity>
</template>

<script lang="ts" setup>
import AppActivity from "@/components/AppActivity.vue";

import {ICON_CANCEL, ICON_MAIL, ICON_OK, ICON_UNDO} from "@/lib/icons";
import {useRouter} from "vue-router";
import {computed, ref, watch} from "vue";
import {useStore} from "vuex";
import LoginPasswordChangeModal from "@/components/login/LoginPasswordChangeModal.vue";
import {useApiClient} from "@/api";
import {hideSpinner, showAlert, showConfirm, showSpinner} from "@/store";
import Configuration from "@/api/types/Configuration";
import GdprText from "@/components/login/GdprText.vue";
import GdprCheckbox from "@/components/login/GdprCheckbox.vue";
import LoginPasswordForgotModal from "@/components/login/LoginPasswordForgotModal.vue";
import ChangePasswordRequest from "@/api/requests/ChangePasswordRequest";
import ProfileDeletionModal from "@/components/login/ProfileDeletionModal.vue";

const store = useStore()
const router = useRouter()
const apiClient = useApiClient()

const username = ref<string>('')
const password = ref<string>('')
const gdprChecked = ref<boolean>(false)
const showPasswordChange = ref<boolean>(false)
const showPasswordForgot = ref<boolean>(false)
const showProfileDeletion = ref<boolean>(false)

const waitingForResetConfirmation = ref<boolean>(false)

const updateIcons = () => {
  const newIcons = []

  if (store.state.configuration) {
    newIcons.push(ICON_MAIL)
    newIcons.push(ICON_UNDO)
  } else {
    newIcons.push(ICON_OK)
  }

  newIcons.push(ICON_CANCEL)

  return newIcons
}

const icons = ref<string[]>([])

watch(() => store.state.configuration, () => {
  username.value = store.state.configuration?.username || null
  password.value = store.state.configuration?.password || null
  gdprChecked.value = (!! store.state.configuration?.sessionId) || false

  icons.value = updateIcons()
}, { immediate: true })

const onSubmit = async () => {
  showSpinner()

  try {
    const response = await apiClient.login(username.value, password.value)

    store.commit('SET_CONFIGURATION', {
      sessionId: response.sessionId,
      username: username.value,
      password: password.value,
      user: response.user,
      lastLogin: new Date()
    } as Configuration)

    await router.push({ name: 'menu' })
  } catch (err: any) {
    console.error(err)
    showAlert('Fehler: ' + err.message, 'Interner Fehler')
  }

  hideSpinner()
}

const onAction = (icon: string) => {
  switch (icon) {
    case ICON_OK:
      if (!username.value.length) {
        showAlert('Sie müssen einen gültigen Benutzernamen eingeben!')
      }
      else if (!password.value.length) {
        showAlert('Sie müssen ein gültiges Passwort eingeben!')
      }
      else if (!gdprChecked.value) {
        showAlert('Sie müssen die Datenschutzrichtlinien akzeptieren um die Anwendung benutzen zu dürfen!')
      }
      else {
        onSubmit()
      }
      break
    case ICON_UNDO:
      waitingForResetConfirmation.value = true
      showConfirm("Sollen die lokalen Daten wirklich unwiederruflich gelöscht werden?", "Lokale Daten löschen")
      break
    case ICON_CANCEL:
      if (store.state.configuration) {
        router.push({ name: 'menu' })
      } else {
        router.push({ name: 'home' })
      }
      break
  }
  console.log('action', icon)
}

watch(() => store.state.alert.lastResult, async (result) => {
  if (result && waitingForResetConfirmation.value) {
    waitingForResetConfirmation.value = false

    showSpinner()
    if (await store.dispatch('RESET_LOCAL_DATA')) {
      showAlert('Die App wurde erfolgreich zurückgesetzt.')
    } else {
      showAlert('Beim Zurücksetzen der App ist ein unerwarteter Fehler aufgetreten')
    }

    hideSpinner()

  }
})

const onPasswordChange = async ({ oldPassword, newPassword }: { oldPassword: string, newPassword: string }) => {
  showSpinner()
  showPasswordChange.value = false

  const api = useApiClient()
  try {
    const res = await api.changePassword(
        store.state.configuration?.sessionId,
        new ChangePasswordRequest(store.state.configuration?.user.username, oldPassword, newPassword))
    console.log('RES', res)
    showPasswordChange.value = false
    hideSpinner()
    await showAlert('Ihr Passwort wurde erfolgreich geändert!')
  } catch (e: any) {
    hideSpinner()
    await showAlert(e.message)
  }
}

const onPasswordForgot = async (email: string) => {
  showSpinner()
  showPasswordChange.value = false

  const api = useApiClient()

  try {
    await api.getNewPassword(email)
    showPasswordForgot.value = false
    hideSpinner()
    await showAlert('Die Passwort - Anfrage wurde erfolgreich übermittelt. Sie erhalten die Zugangsdaten per E-Mail.')
  } catch (e: any) {
    hideSpinner()
    await showAlert(e.message)
  }
}

const onProfileDeletion = async () => {
  showSpinner()
  showProfileDeletion.value = false

  const configuration = store.state.configuration

  await store.dispatch('RESET_LOCAL_DATA')

  const api = useApiClient()
  try {
    await api.deleteUser(configuration.value.user)
    showPasswordForgot.value = false
    hideSpinner()
    await showAlert('Ihr Zugang wurde erfolgreich gelöscht.')
  } catch (e: any) {
    hideSpinner()
    await showAlert(e.message)
  }
}
</script>

<style lang="scss">
.gdpr {
  margin-top: 16px;

  h2 {
    margin-bottom: 0;
  }

  .content {
    font-size: 11pt;
    color: var(--text-light);
  }
}
.check-gdpr {
  padding: 20px 10px 10px 0;
}
ul.form-links li {
  a {
    display: inline-block;
    width: 100%;
    height: 100%;
    padding: 6px 0;
  }
}
</style>
