<template>
  <app-alert
      :show="show"
      title="Zugang löschen"
      show-cancel
      @ok="onOk"
      @cancel="onCancel"
  >
    Mit dem Löschen Ihres Zugangs werden auch alle Ihre ausgefüllten Fragebögen unwiederruflich gelöscht.<br><br>Löschvorgang durchführen?
  </app-alert>
</template>

<script lang="ts" setup>
import AppAlert from "@/components/AppAlert.vue";
import {computed, ref} from "vue";
import {useStore} from "vuex";

const props = withDefaults(defineProps<{
  show?: boolean
}>(), {
  show: false
})

const emit = defineEmits<{
  ok: [ email: string ]
  cancel: []
}>()

const store = useStore()

const email = ref<string>('')

const configuration = computed(() => store.state.configuration)
if (configuration.value) {
  email.value = configuration.value.user.email
}

const onOk = () => {
  emit('ok', email.value)
  email.value = ''
}
const onCancel = () => {
  emit('cancel')
}
</script>
