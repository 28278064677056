<template>
  <div class="activity" :class="`valign-${vertical}`">
    <div class="navbar" type="dark" id="main-navbar">
      <div class="navbar-brand" href="javascript:void(0)">
        {{ title }}
      </div>
      <ul class="icons">
        <li v-for="icon of icons" :key="icon">
          <a href="javascript:void(0);" @click="() => onIconClick(icon)">
            <img :src="icon"/>
          </a>
        </li>
      </ul>
    </div>
    <div class="container" :class="{ 'without-space': fullSize }">
      <div class="content" ref="contentContainer">
        <slot></slot>
      </div>
      <div class="content-footer" ref="contentFooterContainer">
        <slot name="content-footer"></slot>
      </div>
    </div>
    <ul class="fab-icons" v-if="fabIcons">
      <li v-for="icon of fabIcons" :key="icon">
        <a href="javascript:void(0)" @click="() => onFabIconClick(icon)">
          <img :src="icon"/>
        </a>
      </li>
    </ul>
    <footer ref="footerContainer">
      <slot name="footer"></slot>
    </footer>
  </div>
</template>

<script lang="ts" setup>
import {useRouter} from "vue-router";
import {useStore} from "vuex";
import {useI18n} from "vue-i18n";
import {onBeforeUnmount, onMounted, Prop, ref} from "vue";

const props = withDefaults(defineProps<{
  title: string,
  vertical?: 'top'|'center'
  fullSize?: boolean
  icons?: string[]
  fabIcons?: string[]
}>(), {
  vertical:'top',
  fullSize: false
})

const emit = defineEmits<{
  iconClick: [icon: string]
  fabIconClick: [icon: string]
}>()

const { t } = useI18n()
const store = useStore()
const router = useRouter()

const contentContainer = ref<HTMLElement|null>(null)
const contentFooterContainer = ref<HTMLElement|null>(null)
const footerContainer = ref<HTMLElement|null>(null)

const isLogin = router.currentRoute.value.name === 'Forbidden'

const onLogout = () => {
  store.dispatch('AUTH_LOGOUT')
  router.push({ name: 'Forbidden' })
}

const onIconClick = (icon: string) => emit('iconClick', icon)
const onFabIconClick = (icon: string) => emit('fabIconClick', icon)

const checkFooterHeight = () => {
  if (contentContainer.value) {
    const height = contentFooterContainer.value?.getBoundingClientRect().height
    const height2 = footerContainer.value?.getBoundingClientRect().height

    let space = 0
    if (height) {
      space += height
    }
    if (height2) {
      space += height2
    }

    contentContainer.value.style.paddingBottom = `${space}px`
  }
}

onMounted(() => {
  checkFooterHeight()
  window.addEventListener('resize', checkFooterHeight)
})
onBeforeUnmount(() => {
  window.removeEventListener('resize', checkFooterHeight)
})
</script>

<style lang="scss">
.activity {
  background: #fff;

  .navbar-brand {
    cursor: default;
    font-weight: normal;
    font-size: 15pt;
    margin-left: 10px;
  }

  .navbar .icons {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      display: inline-block;

      a {
        display: block;
        max-height: 100%;

        img {
          max-height: 42px;
        }
      }

      &:not(:last-child) {
        padding-right: 5px;
      }
    }
  }

  .arrow-back {
    display: inline-block;
    color: #fff;
    padding: 0 10px 0 0;
  }

  & > .container {
    position: relative;

    &:not(.without-space) {
      padding: 15px;
      min-height: var(--activity-height);
    }
    &.without-space {
      margin: 0;
      max-width: 100%;
      padding-left: 0;
      padding-right: 0;
    }
  }

  &.valign-center > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    position: relative;
  }

  .content-footer {
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 15px;
  }

  ul.fab-icons {
    position: fixed;
    right: 20px;
    bottom: 20px;
    list-style-type: none;
    margin: 0;
    padding: 0;

    a {
      display: block;
      width: 50px;

      img {
        width: 100%;
      }
    }
  }

  footer {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
  }
}
</style>
