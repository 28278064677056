import AbstractEntity from "./AbstractEntity";
import Answer from "@/api/types/Answer";

const TABLE_NAME = 'Answer'

export default class AnswerEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'id' }, [
            'id',
            'questionId',
        ])
    }

    static async getById(db: IDBDatabase, id: number) {
        return this._findOne<Answer>(
            db,
            TABLE_NAME,
            id,
            'id'
        )
    }

    public static async persist(db: IDBDatabase, answer: Answer) {
        const data = super.getPersistData<Answer>(answer, [
            'id',
            'questionId',
            'description',
            'index',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static findForQuestion(db: IDBDatabase, questionId: number) {
        return this._find<Answer>(
            db,
            TABLE_NAME,
            questionId,
            'questionId'
        )
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}