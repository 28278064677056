import AbstractEntity from "@/db/AbstractEntity";
import Configuration from "@/api/types/Configuration";

const TABLE_NAME = 'Configuration'

export default class ConfigurationEntity extends AbstractEntity {
    public static upgrade(db: IDBDatabase, event: IDBVersionChangeEvent): void {
        super._upgrade(db, event, TABLE_NAME, { keyPath: 'username' }, [])
    }

    public static async persist(db: IDBDatabase, configuration: Configuration) {
        const data = this.getPersistData<Configuration>(configuration, [
            'sessionId',
            'lastLogin',
            'lastRefresh',
            'username',
            'password',
            'user',
        ])

        return super._put(db, TABLE_NAME, data)
    }

    static async get(db: IDBDatabase): Promise<Configuration|null> {
        return super._getFirst<Configuration>(db, TABLE_NAME)
    }

    static async clear(db: IDBDatabase): Promise<void> {
        return super.clear(db, TABLE_NAME)
    }
}