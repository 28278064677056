<template>
  <textarea rows="5" v-model="value"></textarea>
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";

const props = defineProps<{
  modelValue: RawAnswer
  question: QuestionEntry
}>()

const emit = defineEmits(["update:modelValue"])

const value = ref<string>('')

watch(value, () => emit("update:modelValue", value.value), { immediate: true })

watch(() => props.question, () => {
  value.value = (props.question.userQuestion ? props.question.userQuestion.stringValue : '') || ''
}, { immediate: true, deep: true })
</script>

<style lang="scss">
.question-component.type-1 {
  textarea {
    width: 100%;
    background: #bcd3a6;
    padding: 4px;

    &, &:focus-visible {
      border: none;
      outline: none;
    }
  }
}
</style>