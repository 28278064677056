<template>
  <label class="checkbox check-gdpr no-form-style">
    <input type="checkbox" v-model="checked" :disabled="store.state.configuration" required>
    Ich erteile die Zustimmung
  </label>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import {ref, watch} from "vue";

const props = defineProps<{
  modelValue: boolean
}>()

const emit = defineEmits(['update:modelValue'])

const store = useStore()
const checked = ref<boolean>(props.modelValue)

watch(checked, () => emit('update:modelValue', checked.value))
</script>