import {App} from "vue";
// @ts-ignore
import o2x from 'object-to-xml'
// @ts-ignore
import {Parser} from 'xml2js'

import {GetReleasesResponse, LoginResponse} from './types'
import ResponseInterface from "@/api/responses/ResponseInterface";
import GetQuestionnairesResponse from "@/api/responses/GetQuestionnairesResponse";
import QuestionnaireReleaseEntity from "@/db/QuestionnaireReleaseEntity";
import getDb, {clearDb} from "@/db/Database";
import QuestionnaireEntity from "@/db/QuestionnaireEntity";
import GetReportResponse from "@/api/responses/GetReportResponse";
import ReportRequest from "@/api/requests/ReportRequest";
import ConfigurationEntity from "@/db/ConfigurationEntity";
import emitter from "@/lib/eventBus";
import UserQuestionnaire from "@/api/types/UserQuestionnaire";
import UserQuestionnaireEntity from "@/db/UserQuestionnaireEntity";
import UserQuestionnaireRequest from "@/api/requests/UserQuestionnaireRequest";
import GetMembershipsResponse from "@/api/responses/GetMembershipsResponse";
import User from "@/api/types/User";
import UpdateRegistrationResponse from "@/api/responses/UpdateRegistrationResponse";
import ChangePasswordRequest from "@/api/requests/ChangePasswordRequest";

class APIClient
{
    private baseUrl: string;
    private xmlParser: Parser;

    private sessionId: string|null = null

    constructor(baseUrl: string) {
        this.baseUrl = baseUrl
        this.xmlParser = new Parser({
            explicitArray: false
        })
    }

    async login(username: string, password: string): Promise<LoginResponse> {
        return this.post('/login', {
            login: {
                username,
                password,
            }
        }, new LoginResponse())
    }

    async getReleases(sessionId: string): Promise<any> {
        await clearDb(true)

        this.sessionId = sessionId
        const response: GetReleasesResponse = await this.post(`/getReleases/[sessionId]`, {}, new GetReleasesResponse())

        const questionnaireIds = []
        for (const release of response.releases) {
            await QuestionnaireReleaseEntity.persist(getDb(), release)
            questionnaireIds.push(release.questionnaireId)
        }

        if (questionnaireIds) {
            await this.getQuestionnaires(sessionId, questionnaireIds)
        }
    }

    async getQuestionnaires(sessionId: string, ids: number[]) {
        this.sessionId = sessionId
        const response: GetQuestionnairesResponse = await this.post(`/getQuestionnairesByIds/[sessionId]/${ids.join(',')}`, {}, new GetQuestionnairesResponse())

        for (const questionnaire of response.questionnaires) {
            await QuestionnaireEntity.persist(getDb(), questionnaire)
        }
    }

    async saveUserQuestionnaire(sessionId: string, userQuestionnaire: UserQuestionnaire) {
        this.sessionId = sessionId
        const updatedUserQuestionnaire = await this.post(
            '/saveUserQuestionnaire/[sessionId]',
            await (new UserQuestionnaireRequest(userQuestionnaire)).get(),
            new UserQuestionnaire({}))

        if (!userQuestionnaire.deleted) {
            updatedUserQuestionnaire.pk = userQuestionnaire.pk
            updatedUserQuestionnaire.dirty = 0
            await UserQuestionnaireEntity.persist(getDb(), updatedUserQuestionnaire)
        } else {
            userQuestionnaire.dirty = 0
            await UserQuestionnaireEntity.persist(getDb(), userQuestionnaire)
        }
    }

    async getMemberships(): Promise<GetMembershipsResponse> {
        return await this.post('/getMemberships', {}, new GetMembershipsResponse())
    }

    async saveRegistration(user: User): Promise<void> {
        const request = {
            ...user,
            locations: user.locations.map(location => ({ location }))
        }
        return await this.post(`/saveRegistration`, {
            user: request,
        })
    }

    async deleteUser(user: User): Promise<void> {
        const request = {
            ...user,
            locations: user.locations.map(location => ({ location }))
        }
        return await this.post(`/deleteUser`, {
            user: request,
        })
    }

    async updateRegistration(sessionId: string, user: User): Promise<void> {
        const request = {
            ...user,
            locations: user.locations.map(location => ({ location }))
        }
        return await this.post(`/updateRegistration/${sessionId}`, {
            user: request,
        })
    }

    async getNewPassword(mailAddress: string): Promise<void> {
        return await this.post(`/getNewPassword`, mailAddress, null, false, true)
    }

    async changePassword(sessionId: string, request: ChangePasswordRequest) {
        return await this.post(`/changePassword/${sessionId}`, { changePassword: request })
    }

    async createReport(sessionId: string, request: ReportRequest): Promise<GetReportResponse> {
        const reportRequest: any = { ...request }

        if (reportRequest.topics) {
            reportRequest.topics = { topicId: reportRequest.topics }
        }

        this.sessionId = sessionId
        return await this.post(`/getReport/[sessionId]`, {
            reportRequest,
        }, new GetReportResponse())
    }

    async post(path: string, data: any = {}, resultObject: ResponseInterface|null = null, isRetry: boolean = false, postPlain: boolean = false): Promise<any> {
        const url = (this.baseUrl + path)
            .replace('[sessionId]', this.sessionId || '')

        let res = await fetch(url, {
            method: 'POST',
            body: postPlain ? data : o2x(data),
            headers: {
                'Content-Type': 'text/xml',
            }
        });

        if (res.status === 403 && !isRetry) {
            const configuration = await ConfigurationEntity.get(getDb());
            if (configuration?.username && configuration.password) {
                const response = await this.login(configuration.username, configuration.password)
                const newSessionId = response.sessionId

                if (newSessionId) {
                    emitter.emit('API_SESSION_REFRESH', newSessionId)
                    this.sessionId = newSessionId
                    return await this.post(path, data, resultObject, true, postPlain)
                }
            }
        }

        if (res.status !== 200) {
            const txt = await res.text()
            console.error('API request failed with non-200 status', res.status, res.statusText, txt)
            throw new Error(txt)
        }

        if (resultObject) {
            if (!resultObject.isXmlResponse()) {
                const blob = await res.blob()
                resultObject.setBlob(blob)
            } else {
                const str: string = await res.text();
                const object = await this.xmlParser.parseStringPromise(str)
                resultObject.parse(object)
            }
        }

        return resultObject
    }
}

let apiClient: APIClient|null = null

export default {
    install(app: App, { baseUrl }: { baseUrl: string }) {
        if (typeof baseUrl === 'undefined') {
            throw new Error('no base url provided')
        }

        apiClient = new APIClient(baseUrl)
    }
}

export const useApiClient = (): APIClient => {
    if (!apiClient) {
        throw new Error('APIClient not installed')
    }

    // @ts-ignore
    return apiClient
}
