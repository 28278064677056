<template>
  <div class="snackbar-container">
    <Transition name="snackbar">
      <div class="app-snackbar" v-show="show">
        {{ message }}
      </div>
    </Transition>
  </div>
</template>

<script lang="ts" setup>
import {useStore} from "vuex";
import {computed, watch} from "vue";

const store = useStore()

const show = computed(() => store.state.snackbar.show)
const message = computed(() => store.state.snackbar.message)
</script>

<style lang="scss">
.app-snackbar {
  position: fixed;
  left: 0;
  bottom: 0;
  width: 100%;
  background: #323232;
  color: #fff;
  padding: 13px 20px;
  font-size: 11pt;
  transition: all .2s;
}

.snackbar-enter-active,
.snackbar-leave-active {
  transform: translateY(0);
}

.snackbar-enter-from,
.snackbar-leave-to {
  transform: translateY(100%);
}
</style>
