<template>
  <div class="check-result">
    <div class="area">
      <div class="inner" v-if="completed">
        Sie haben den Frageboxen nun vollständig ausgefüllt und können den Fragebogen nun abschließen.
      </div>
      <div class="inner" v-else>
        Sie haben den Fragebogen noch nicht vollständig ausgefüllt und können den Fragebogen daher nicht abschließen.
      </div>
    </div>
    <button :disabled="!completed" @click="save">
      Fragebogen abschließen
    </button>
    <div class="info">
      Das Abschließen speichert den aktuellen Fragebogen und Ihre Antworten!
    </div>
    <div class="navigation">
      <slot name="navigation"></slot>
    </div>
  </div>
</template>

<script lang="ts" setup>
import {useApiClient} from "@/api";
import {useRouter} from "vue-router";
import {useStore} from "vuex";

const props = defineProps<{
  completed: boolean
}>()

const api = useApiClient()
const store = useStore()
const router = useRouter()

const save = async () => {
  await store.dispatch('SAVE_QUESTIONNAIRE')
  await router.push({ name: 'menu' })
}
</script>

<style lang="scss">
.check-result {
  .area {
    background: var(--question-question-background);
    text-align: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    font-size: 16pt;
    line-height: 17pt;
    font-weight: 500;
    padding-bottom: calc(100% - 20px);
    position: relative;

    .inner {
      padding: 10px;
      position: absolute;
      top: 50%;
      transform: translateY(-50%);
    }
  }

  button {
    margin: 20px 0;
    width: 100%;
    border: none;
    padding: 10px;
    text-transform: uppercase;
    font-size: 11pt;
    background: #d9d9d9;
  }

  .info {
    text-align: center;
    font-size: 13pt;
    line-height: 15pt;
    color: #7b7b7b;
  }
}
</style>