import UserQuestionnaireTopic from "./UserQuestionnaireTopic";
import UserQuestionnaireCategory from "./UserQuestionnaireCategory";
import UserQuestion from "./UserQuestion";
import AbstractType from "@/api/types/AbstractType";
import {extractArrayValues, intOrNull, strToBoolNum} from "@/api/lib";
import ResponseInterface from "@/api/responses/ResponseInterface";

export default class UserQuestionnaire extends AbstractType implements ResponseInterface {
    public pk: number|null
    public id: number|null
    public lastQuestionId: number|null
    public progress: number
    public releaseId: number
    public state: number
    public locationId: number|null
    public completionDate: string|null
    public description: string
    public deleted: number|boolean
    public dirty: number|boolean
    public questions: UserQuestion[]
    public topics: UserQuestionnaireTopic[]
    public categories: UserQuestionnaireCategory[] = []

    constructor(data: any) {
        super()
        this.pk = intOrNull(data.pk)
        this.id = intOrNull(data.id)
        this.lastQuestionId = intOrNull(data.lastQuestionId)
        this.progress = parseInt(data.progress)
        this.releaseId = parseInt(data.releaseId)
        this.state = parseInt(data.state)
        this.locationId = intOrNull(data.locationId)
        this.completionDate = data.completionDate || null
        this.description = data.description
        this.dirty = typeof data.dirty !== 'undefined' ? data.dirty : false
        this.deleted = strToBoolNum(data.deleted)

        this.questions = extractArrayValues<UserQuestion>(
            data,
            'questions.question',
            (item: any) => new UserQuestion(item)
        )
        this.topics = extractArrayValues<UserQuestionnaireTopic>(
            data,
            'userQuestionnaireTopics.userQuestionnaireTopic',
            (item: any) => new UserQuestionnaireTopic(item)
        )

        this.categories = extractArrayValues<UserQuestionnaireCategory>(
            data,
            'userQuestionnaireCategories.userQuestionnaireCategory',
            (item: any) => new UserQuestionnaireCategory(item)
        )
    }

    isXmlResponse(): boolean {
        return true;
    }

    async parse(data: any) {
        if (data && typeof data.userQuestionnaire !== 'undefined') {
            data = data.userQuestionnaire

            this.pk = intOrNull(data.pk)
            this.id = intOrNull(data.id)
            this.lastQuestionId = intOrNull(data.lastQuestionId)
            this.progress = parseInt(data.progress)
            this.releaseId = parseInt(data.releaseId)
            this.state = parseInt(data.state)
            this.locationId = intOrNull(data.locationId)
            this.completionDate = data.completionDate || null
            this.description = data.description
            this.dirty = typeof data.dirty !== 'undefined' ? data.dirty : false
            this.deleted = strToBoolNum(data.deleted)

            this.topics = Object.values(data.userQuestionnaireTopics)
            this.categories = Object.values(data.userQuestionnaireCategories)
        }
    }

    setBlob(blob: Blob): void {}
}