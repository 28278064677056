export default class QuestionnaireCategory {
    public id: number
    public name: string|null
    public description: string|null
    public questionnaireId: number
    public color: string|null

    constructor(data: any) {
        this.id = parseInt(data.id)
        this.name = data.name || null
        this.description = data.description || null
        this.questionnaireId = parseInt(data.questionnaireId)
        this.color = data.color || null
    }
}