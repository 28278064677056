<template>
  <router-view v-if="store.state.initialized"/>

  <app-alert
      :show="show"
      :title="title"
      :message="message"
      :button-label="buttonLabel"
      :show-cancel="showCancel"
      :cancel-button-label="cancelButtonLabel"
      :small-font="smallFont"
      @ok="onAlertOk"
      @cancel="onAlertCancel"
  />

  <app-spinner/>
  <app-snackbar/>
  <offline-data-upload/>
</template>

<script setup>
import AppAlert from "@/components/AppAlert.vue";
import AppSpinner from "@/components/AppSpinner.vue";

import {useStore} from "vuex";
import {computed} from "vue";
import AppSnackbar from "@/components/AppSnackbar.vue";
import OfflineDataUpload from "@/components/OfflineDataUpload.vue";

const store = useStore()

const show = computed(() => store.state.alert.show)
const title = computed(() => store.state.alert.title)
const message = computed(() => store.state.alert.message)
const buttonLabel = computed(() => store.state.alert.button)
const showCancel = computed(() => store.state.alert.showCancel)
const cancelButtonLabel = computed(() => store.state.alert.cancelButtonLabel)
const smallFont = computed(() => store.state.alert.smallFont)

const onAlertOk = () => store.commit('HIDE_ALERT', true)
const onAlertCancel = () => store.commit('HIDE_ALERT', false)

store.dispatch('INITIALIZE')
</script>

<style lang="scss">
@import '@/assets/scss/app';
</style>
