<template>
  <input type="date" v-model="value">
</template>

<script lang="ts" setup>
import {ref, watch} from "vue";
import {QuestionEntry, RawAnswer} from "@/lib/QuestionHandler";

const props = defineProps<{
  modelValue: RawAnswer
  question: QuestionEntry
}>()

const emit = defineEmits(["update:modelValue"])

const value = ref<string|null>(null)

watch(value, () => emit("update:modelValue", value.value), { immediate: true })

watch(() => props.question, () => {
  value.value = (props.question.userQuestion ? props.question.userQuestion.dateValue?.split('T')[0] : null) || null
}, { immediate: true, deep: true })
</script>

<style lang="scss">
.question-component.type-5 {
  input[type=date] {
    background: #d9d9d9;
    width: 100%;
    text-align: center;
    padding: 10px;
    -webkit-appearance: none;

    &::-webkit-inner-spin-button,
    &::-webkit-calendar-picker-indicator {
      display: none;
      -webkit-appearance: none;
    }

    &, &:focus-visible {
      border: none;
      outline: none;
    }
  }
}
</style>